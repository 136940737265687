import React, {FC, ReactNode} from "react";
import styles from './styles.module.scss';
import cn from "classnames";

interface IProps {
    children: ReactNode;
    className?: string;
}

const Title: FC<IProps> = ({children, className}) => (<h1 className={cn(styles.title, {[className || '']: className})}>{children}</h1>);

export default Title;
