import React, {FC, memo, useContext, useEffect, useMemo, useState} from "react";

import styles from './styles.module.scss';
import {ICustomMap} from "types/ICustomMap";
import cn from "classnames";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";

const itemsPerRow = 3;

interface IProp {
    showSlide?: number;
    list: ICustomMap[];
    onChange: (item: ICustomMap) => void;
}

const Slider: FC<IProp> = ({showSlide, list, onChange}) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);

    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const [dataIndex, setDataIndex] = useState<number>(0);
    const [length, setLength] = useState<number>(list?.length);
    const [touchPosition, setTouchPosition] = useState(null);

    useEffect(() => {
        setLength(list.length);
    }, [list]);

    useEffect(() => {
        if (typeof showSlide !== 'undefined') {
            setCurrentIndex(showSlide);
            setDataIndex(showSlide + 1);
        }
    }, [showSlide])

    const next = () => {
        const allow = currentIndex !== length - (itemsPerRow - 1);
        if (allow) {
            setCurrentIndex(prevState => prevState + 1);
            setDataIndex(prevState => {
                const index = prevState + 1;
                onChange(list[index])
                return index;
            });
        }
    }

    const prev = () => {
        const allow = currentIndex > -1;
        if (allow) {
            setCurrentIndex(prevState => prevState - 1);
            setDataIndex(prevState => {
                const index = prevState - 1;
                onChange(list[index])
                return index;
            });
        }
    }

    const handleTouchStart = (e: ICustomMap) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    }

    const handleTouchMove = (e: ICustomMap) => {
        const touchDown = touchPosition

        if(touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            next()
        }

        if (diff < -5) {
            prev()
        }

        setTouchPosition(null)
    }

    const transform = useMemo(() => {
            const translateVal = currentIndex === -1 ? 100 : currentIndex * 100;
            const sign = currentIndex > 0 ? '-' : '';
            return `translateX(${sign}${translateVal}%)`;
    }, [currentIndex]);

    return (
        <div className={styles.carouselContainer}>
            <button className={styles.leftArrow} onClick={prev}>
                <ArrowLeft />
            </button>
            <div className={styles.text}>{commonTxts.page} {dataIndex + 1}-{list.length}</div>
            <div className={styles.carouselWrapper}>
                <div className={styles.carouselContentWrapper} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div
                        className={cn(styles.carouselContent, styles.showMultiple)}
                        style={{transform: `${transform}`}}
                    >
                        {
                            list?.map(({ img }, idx) => {
                                const index = currentIndex + 1;
                                const isActive = idx === index;
                                return (
                                    <div className={cn(styles.imgBox, {[styles.active]: isActive})} key={idx}>
                                        <div>
                                            <img src={img} alt='' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <button className={styles.rightArrow} onClick={next}>
                <ArrowRight/>
            </button>
        </div>
    )
}

export default memo(Slider);
