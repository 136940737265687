import React, {FC, useCallback, useContext, useEffect, useState} from "react";

import {Context} from "pages/Home/context";
import {textsCommon} from "texts/common";
import {LangActionsContext} from "context/lang";
import commonStyles from 'styles/common.module.scss';
import styles from './styles.module.scss';
import UserPhoto from "../../UserPhoto";
import {flags} from "config/flags";
import {deleteRecord, deleteRecordFolder, updateSmartObject} from "utils/firebase";
import {ISmartObject} from "types/ISmartObject";
import {AppActionsContext} from "../../../context/app";
import SavingOverlay from "../../SavingOverlay";

const ManageRecords: FC = () => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const { narrators, smartObject } = useContext(Context);
    const { fetchSmartObject } = useContext(AppActionsContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const notFinishedRecords = narrators?.filter(({ full_audio }) => !full_audio);

    const { id: smartObjectId } = smartObject || {};

    useEffect(() => {
        if (smartObjectId) {
            fetchSmartObject(smartObjectId);
        }
    }, [smartObjectId, fetchSmartObject])

    const handleRemove = useCallback((id: string) => async () => {
        if (smartObject?.id) {
            setIsLoading(true);
            const recList = smartObject.recordingsList.filter((recId) => recId !== id);
            try {
                await updateSmartObject(smartObject?.id, { recordingsList: recList } as ISmartObject);
                await deleteRecord(id);
                await deleteRecordFolder(id);
                await fetchSmartObject(smartObject.id)
            } catch (err) {
                console.error(err)
            } finally {
                setIsLoading(false);
            }
        }
    }, [smartObject, fetchSmartObject])

    return (
        <div className={styles.root}>
            {isLoading && <SavingOverlay noText />}
            {
                !!notFinishedRecords?.length && (
                    <>
                        <h3>{commonTxts.notFinishedRecords}</h3>
                        <div className={styles.box}>
                            {
                                notFinishedRecords.map(({ narratorAvatar, lang, id }) => (
                                    <div className={commonStyles.photoBoxBorder}>
                                        <UserPhoto
                                            photo={narratorAvatar.src}
                                            flag={flags[lang]}
                                            handleRemove={handleRemove(id)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ManageRecords;
