import {localStorageKeys} from "config/localStorageKeys";
import {ICachedBookInfo, ICachedBooksInfo} from "types/ICachedBooksInfo";

export const cacheBookInfo = (dataToCache: ICachedBookInfo): void => {
    const accessKey = localStorage.getItem(localStorageKeys.appAccessKey);
    const cachedBooksConfig: ICachedBooksInfo = JSON.parse(localStorage.getItem(localStorageKeys.booksConf) || '{}');
    const smartObjectId = accessKey ? accessKey.split('-')[1] : null;

    if (smartObjectId) {
        const data = {
            ...cachedBooksConfig,
            [smartObjectId]: {
                ...(cachedBooksConfig[smartObjectId] || {}),
                ...dataToCache,
            }
        }
        localStorage.setItem(localStorageKeys.booksConf, JSON.stringify(data));
    }
}
