const textsRecording = {
    en: {
        narratorLang: 'Language of the book narration',
        letChildReadStoryVoice: 'Let the child read the story with your voice',
        recordUploading: 'Your recording is uploading',
        goHome: 'GO HOME (see it)',
        uploadComplete: 'Uploading step completed',
        congratulations: 'Congratulations',
        uploadedMsg: 'You record successfully uploaded. The child can read the book with your narration.',
        yourAvatar: 'Your Avatar',
        bookRecording: 'Book recording',
        review: 'Review',
        upload: 'Upload',
        maxRecordsReached: 'Reached max narrations for this book, max 5 records is allowed',
    },
    uk: {
        narratorLang: 'Мова запису для книжки',
        letChildReadStoryVoice: 'Нехай дитина прочитає казку вашим голосом',
        recordUploading: 'Ваш запис завантажується',
        goHome: 'На домашню (побачити)',
        uploadComplete: 'Етап завантаження завершено',
        congratulations: 'Вітаємо',
        uploadedMsg: 'Ваш запис успішно завантажено. Дитина може прочитати книгу з вашою розповіддю.',
        yourAvatar: 'Ваш аватар',
        bookRecording: 'Запис Книги',
        review: 'Перегляд',
        upload: 'Завантаження',
        maxRecordsReached: 'Досягнуто максимальної кількості оповідань для цієї книги, дозволено не більше 5 записів',
    }
}

export { textsRecording }
