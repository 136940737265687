const textsCommon =  {
    en: {
        confirm: 'Confirm',
        record: 'Record',
        recordBook: 'Record Book',
        stop: 'Stop',
        start: 'Start',
        approve: 'Approve',
        saving: 'Saving',
        errorHappened: 'Error happened, please try again later',
        needToFinnishPage: 'You need to finish page(s)',
        sec: 'sec',
        page: 'Page',
        uploading: 'Uploading',
        family: 'Family',
        narrators: 'Narrators',
        parentsOnly: 'Parents only',
        youReaInRecControl: 'You are in control of recording',
        answerToContinue: 'To continue please enter correct answer',
        yourAnswer: 'Your Answer',
        accept: 'Accept',
        answNotCorrect: 'Your answer is not correct',
        chooseRecType: 'Choose your type of recording',
        askRecordRemote: 'Ask to record remotely',
        askRecordRemoteDesc: 'Let father or other family members narrate the book for the child',
        narrateYourself: 'Narrate yourself',
        narrateYourselfDesc: 'You will be narrating the book for the child',
        familyMemberWillReceiveThis: 'Family member will receive this.',
        ascToRec: 'Ask to record',
        sendRecLink: 'Send recording link',
        maxNarratorsMsg: 'Up to 5 people can narrate one book',
        copiedToClipboard: 'Link is copied to clipboard',
        noRecordingsMsg: 'No recordings, please add your own recording',
        haveBookMsg: 'You have physical book in front of you',
        remoteRecMsg: 'Remotely, They don’t have a physical book, get a link to record while reading from the phone',
        narrationNotFound: 'Narration is not found',
        enterValue: 'Please enter value',
        audioIsNotGenerated: 'Audio is not generated',
        addPhotoHelpText: 'Add the photo of child(ren) so family member see for whom they narrate.',
        addYourPhotoHelpText: 'Add your photo. For the child see who reads the story',
        manageRecords: 'Manage records',
        notFinishedRecords: 'Not Finished Records',
        or: 'Or',
        copy: 'Copy',
    },
    uk: {
        confirm: 'Підтвердити',
        record: 'Записати',
        recordBook: 'Записати книгу',
        stop: 'Стоп',
        start: 'Розпочати',
        approve: 'Зберегти',
        saving: 'Збереження',
        errorHappened: 'Сталась помилка, спробуйте пізніше',
        needToFinnishPage: 'Вам необхідно завершити сторінку(и)',
        sec: 'сек',
        page: 'Сторінка',
        uploading: 'Завантаження',
        family: 'Сім\'я',
        narrators: 'Оповідачі',
        parentsOnly: 'Тільки батьки',
        youReaInRecControl: 'Ви в режимі контролю записів',
        answerToContinue: 'Для продовження введіть правильну відповідь',
        yourAnswer: 'Ваша відповідь',
        accept: 'Прийняти',
        answNotCorrect: 'Ваша відповідь не правильна',
        chooseRecType: 'Виберіть тип запису',
        askRecordRemote: 'Запис віддалено',
        askRecordRemoteDesc: 'Нехай батько або інші члени сім’ї розкажуть книгу для дитини',
        narrateYourself: 'Розкажіть самі',
        narrateYourselfDesc: 'Ви запишете розповідь книги для дитини',
        familyMemberWillReceiveThis: 'Це отримає член сім’ї.',
        ascToRec: 'Попросіть записати',
        sendRecLink: 'Надішліть посилання на запис',
        maxNarratorsMsg: 'Одну книгу можуть розповідати до 5 осіб',
        copiedToClipboard: 'Посилання скопійовано в буфер обміну',
        noRecordingsMsg: 'Немає записів, додайте власний запис',
        haveBookMsg: 'Перед вами фізична книга',
        remoteRecMsg: 'Віддалено, у них немає фізичної книги, отримайте посилання для запису під час читання з телефону',
        narrationNotFound: 'Оповідання не знайдено',
        enterValue: 'Будь ласка, введіть значення',
        audioIsNotGenerated: 'Аудіо не згенеровано',
        addPhotoHelpText: 'Додайте фотографію дитини(дітей), щоб член сім’ї бачив, для кого він розповідає.',
        addYourPhotoHelpText: 'Додайте своє фото. Щоб дитина подивилася, хто читає казку',
        manageRecords: 'Керування записами',
        notFinishedRecords: 'Незавершені записи',
        or: 'Або',
        copy: 'Копіювати',
    }
}

export { textsCommon };
