import React, {FC, useContext, useEffect, useState} from "react";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";

import styles from './styles.module.scss';
import {Context} from "../context";
import UserPhoto from "../../UserPhoto";
import {AppContext} from "context/app";
import Button from "../../Button";
import {textsRecording} from "texts/recording";
import CheckMark from "../../icons/CheckMark";
import {Link} from "react-router-dom";
import routes from "config/routes";
import {flags} from "../../../config/flags";

const duration = 15000;

interface IProps {
    hideHomeButton?: boolean;
}

const Save: FC<IProps> = ({ hideHomeButton }) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const recordTxts = getLangTexts(textsRecording);
    const { book, record, recordLang } = useContext(Context);
    const { smartObject } = useContext(AppContext);
    const [progress, setProgress] = useState<number>(0);

    const uploaded = progress === 100;

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((val => {
                if (val >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return val + 1000 / duration;
            }));
        }, 10)
    }, []);

    const title = uploaded ? recordTxts.congratulations : `${commonTxts.uploading}...`;
    const msg = uploaded ? recordTxts.uploadedMsg : recordTxts.recordUploading;

    return (
        <section className={styles.wrap}>
            <h1>{title}</h1>
            <p>{msg}</p>
            <div className={styles.cover}><img src={book?.coverImg.src} alt={book?.name} /></div>
            <div className={styles.avatars}>
                { smartObject?.ownerAvatar.src && <UserPhoto photo={smartObject?.ownerAvatar.src} />}
                <UserPhoto photo={record?.narratorAvatar.src} flag={recordLang && flags[recordLang]} />
            </div>
            {
                !uploaded && (
                    <div className={styles.progressBox}>
                        <div className={styles.progress}><span style={{width: `${progress}%`}} /></div>
                        <div className={styles.nums}>
                            <span>0</span>
                            <span>100%</span>
                        </div>
                    </div>
                )
            }
            {
                uploaded && (<div className={styles.uploadComplete}><CheckMark />{recordTxts.uploadComplete}</div>)
            }
            {uploaded && !hideHomeButton && <Link to={routes.home}><Button className={styles.btn}>{recordTxts.goHome}</Button></Link>}
        </section>
    )
}

export default Save;
