import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ActionsContext, Context} from "../context";
import {toBase64} from "utils/fileToBase64";
import {useReactMediaRecorder} from "hooks/useMediaRecorder";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../../firebase";
import {updateRecord} from "utils/firebase";
import {IRecord} from "types/IRecord";
import {Steps} from "../config";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import {ENDED} from "./config";

const pingDuration = 4000;

const useRecording = () => {
    const {record, recordLang, book} = useContext(Context);
    const {setActiveStep, fetchRecord, setDisableOpenSider} = useContext(ActionsContext);

    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);

    const [activePageNumber, setActivePageNumber] = useState<number>(1);
    const [imgFullScreen, setImgFullScreen] = useState<boolean>(false);
    const [currentRecord, setCurrentRecord] = useState<string | undefined>();
    const [file, setFile] = useState<Blob | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [err, setErr] = useState<string | undefined>();
    const [canGenerateFullAudio, setCanGenerateFullAudio] = useState<boolean>(false);
    const [isRecCountDone, setIsRecCountDone] = useState<boolean>(false);

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const recordTimer = useRef<any>();
    const recCountDown = useRef<any>();

    useEffect(() => {
        if (record && activePageNumber) {
            const rec = record?.pages?.find(({pageNum}) => pageNum === activePageNumber)?.src || undefined;
            setCurrentRecord(rec);
        }
    }, [record, activePageNumber]);

    const onRecordStop = useCallback(async (url: string, file: Blob) => {
        const res = await toBase64(file);
        setFile(file);
        setCurrentRecord(res || undefined);
    }, [])

    const onRecStart = useCallback(() => {
        setIsRecording(true);
    }, [])

    const {startRecording, stopRecording, pauseRecording, clearBlobUrl} =
        useReactMediaRecorder({
            blobPropertyBag: {type: 'audio/mpeg'},
            audio: true,
            onStop: onRecordStop,
            onStart: onRecStart,
        });
    const activePage = book?.recPages?.find(({pageNum}) => pageNum === activePageNumber);
    const activeText = recordLang ? activePage?.text[recordLang] || '' : '';

    useEffect(() => {
        if (isRecCountDone && isRecording) {
            recCountDown.current.stop();
            recordTimer.current?.start();
        }
    }, [isRecCountDone, isRecording])

    const toggleImgFullScreen = useCallback(() => {
        setImgFullScreen(val => !val);
    }, []);

    const handleRecordPress = useCallback(async () => {
        await startRecording();
        await pauseRecording();
        recCountDown.current?.start();
        recordTimer.current?.reset();
        setIsRecCountDone(false);
        setDisableOpenSider(true);

    }, [setDisableOpenSider, startRecording, pauseRecording]);

    const onCountDownDone = useCallback(async () => {
        await startRecording();
        setIsRecCountDone(true);
    }, [startRecording])

    const handleStopRecording = useCallback(() => {
        recordTimer.current?.pause();
        stopRecording();
        setCanGenerateFullAudio(true);
        setDisableOpenSider(false);
        setIsRecording(false);
        setIsRecCountDone(false);
    }, [stopRecording, setDisableOpenSider]);

    const pagesWithRecords = useMemo(() => record?.pages?.map(({pageNum}) => pageNum), [record]);

    const canGoNext = useMemo(() => {
        return !!record?.pages?.find(({pageNum}) => pageNum === activePageNumber) || file;
    }, [record, activePageNumber, file]);

    const audioRecord = useMemo(() => currentRecord ? new Audio(currentRecord) : null, [currentRecord]);
    const prevAudioRef = useRef<HTMLAudioElement>();
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const onEnd = useCallback(() => {
        setIsPlaying(false);
    }, []);

    useEffect(() => {
        if (audioRecord) {
            if (prevAudioRef.current) {
                prevAudioRef.current.removeEventListener(ENDED, onEnd);
                prevAudioRef.current.currentTime = 0;
                prevAudioRef.current.pause();
                setIsPlaying(false);
            }
            audioRecord.addEventListener(ENDED, onEnd);
            prevAudioRef.current = audioRecord;
        }
        return () => {
            audioRecord?.removeEventListener(ENDED, onEnd);
        }
    }, [audioRecord, onEnd])

    const handlePlayPress = useCallback(() => {
        if (audioRecord) {
            setIsPlaying(true);
            audioRecord.play();
        }
    }, [audioRecord]);

    const handlePausePress = useCallback(() => {
        if (audioRecord) {
            setIsPlaying(false);
            audioRecord.pause();
        }
    }, [audioRecord]);

    const resetAudio = useCallback(() => {
        if (prevAudioRef.current) {
            prevAudioRef.current.currentTime = 0;
            prevAudioRef.current.pause();
        }
        setIsPlaying(false);
    }, [])

    const handleReRecord = useCallback(async () => {
        await clearBlobUrl();
        handlePausePress();
        handleRecordPress();
        // startRecording();

    }, [handlePausePress, clearBlobUrl, handleRecordPress]);

    const handleCloseErrModal = useCallback(() => {
        setErr(undefined);
    }, [])

    const handleSaveFile = useCallback(async (generateFullAudio: boolean) => {
        if (record?.id) {
            try {
                if (file || generateFullAudio) {
                    setIsLoading(true)
                }
                const metadata = {
                    contentType: 'audio/mpeg',
                };
                const recId = record?.id;

                if (file) {
                    const storageRef = ref(storage, `_recordings/${recId}/pages/${activePageNumber}`);
                    const snap = await uploadBytes(storageRef, file, metadata);
                    const src = await getDownloadURL(snap.ref);

                    let pageIndex = record.pages.findIndex(({pageNum}) => pageNum === activePageNumber);
                    pageIndex = pageIndex >= 0 ? pageIndex : activePageNumber - 1;
                    const pages = record.pages || [];
                    const firstPage = activePageNumber === 1;
                    const lastPage = activePageNumber === record.pages.length;
                    pages[pageIndex] = {
                        src,
                        duration: recordTimer.current.duration + (firstPage || lastPage ? pingDuration / 2 : pingDuration),
                        pageNum: activePageNumber,
                    }

                    await updateRecord(recId, {pages} as IRecord);
                }

                if (generateFullAudio) {
                    await updateRecord(recId, {full_audio: {src: ''}, full_transition_audio: {src: ''}} as IRecord);
                    await fetch(`https://startmergeprocessing-7ht63x7rgq-ew.a.run.app/?legacy=1&recording=${recId}`, {
                        mode: 'cors',
                    });
                }

                if (file || generateFullAudio) {
                    await fetchRecord(recId);
                }

                setFile(undefined);
                clearBlobUrl();
                recordTimer?.current?.reset();
            } catch (err) {
                setIsLoading(false);
                setErr(commonTxts.errorHappened);
                window.console.log(err);
            } finally {
                setIsLoading(false);
            }
        }
    }, [activePageNumber, clearBlobUrl, file, fetchRecord, record, commonTxts]);

    const handlePrevPress = useCallback(async () => {
        await handleSaveFile(false);
        setActivePageNumber(val => val - 1);
    }, [handleSaveFile]);

    const handleNextPress = useCallback(async () => {
        resetAudio();

        const generateMp3 = (activePageNumber === book?.recPages.length) && canGenerateFullAudio;
        await handleSaveFile(generateMp3);
        if (activePageNumber === book?.recPages.length) {
            setActiveStep(Steps.review);
            return;
        }
        setActivePageNumber(val => val + 1);
    }, [activePageNumber, book, setActiveStep, handleSaveFile, resetAudio, canGenerateFullAudio]);


    return useMemo(() => ({
        commonTxts,
        book,
        activePageNumber,
        activePage,
        isLoading,
        pagesWithRecords,
        activeText,
        imgFullScreen,
        isPlaying,
        currentRecord,
        recordTimer,
        recCountDown,
        err,
        toggleImgFullScreen,
        canGoNext,
        isRecording,
        handlePrevPress,
        handleNextPress,
        handleReRecord,
        handlePausePress,
        handlePlayPress,
        handleStopRecording,
        handleCloseErrModal,
        handleRecordPress,
        onCountDownDone,
    }), [
        commonTxts,
        book,
        activePageNumber,
        activePage,
        isLoading,
        isRecording,
        pagesWithRecords,
        activeText,
        imgFullScreen,
        isPlaying,
        currentRecord,
        recordTimer,
        recCountDown,
        err,
        toggleImgFullScreen,
        canGoNext,
        handlePrevPress,
        handleNextPress,
        handleReRecord,
        handlePausePress,
        handlePlayPress,
        handleStopRecording,
        handleCloseErrModal,
        handleRecordPress,
        onCountDownDone,
    ])
}

export default useRecording;
