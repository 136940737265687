import React, {FC} from "react";

const ArrowRight: FC = () => (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.0835 17H26.9168" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 7.0835L26.9167 17.0002L17 26.9168" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowRight;
