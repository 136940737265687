import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import styles from "./styles.module.scss";
import Button from "../Button";
import Modal from "../Modal";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import {updateSmartObject} from "../../utils/firebase";
import {ISmartObject} from "../../types/ISmartObject";

const getSecret = () => {
    const data = '1234567890aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ';
    const idx1 = Math.floor(Math.random() * data.length);
    const idx2 = Math.floor(Math.random() * data.length);
    const idx3 = Math.floor(Math.random() * data.length);
    const idx4 = Math.floor(Math.random() * data.length);
    return `${data[idx1]}${data[idx2]}${data[idx3]}${data[idx4]}`;
}

interface IProps {
    disabled?: boolean;
    label: string;
    subLabel?: string;
    smartObject: ISmartObject;
}

const AskToRecordBtn: FC<IProps> = ({disabled, label, subLabel, smartObject}) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false);
    const [shareUrl, setShareUrl] = useState<string>();

    useEffect(() => {
        if (smartObject) {
            let secret = smartObject.remoteRecSecret;
            if (!secret) {
                // @ts-ignore
                window.DD_RUM && window.DD_RUM?.addAction('Setting remoteRecSecret code to smart object', {smartObjectId: smartObject.id})
                secret = getSecret();
                updateSmartObject(smartObject.id, {remoteRecSecret: secret} as ISmartObject);
            }
            setShareUrl(`${window.location.href}remote-record?recKey=${smartObject.id}-${secret}`);
        }
    }, [smartObject])

    const handleCopy = useCallback(() => {
        if (navigator.clipboard && shareUrl) {
            navigator.clipboard.writeText(shareUrl).then(() => {
                setShowCopyModal(true);
            }).catch(console.error);
            // @ts-ignore
            window.DD_RUM && window.DD_RUM?.addAction('Copied recording link', {link: shareUrl})
        } else {
            // @ts-ignore
            window.DD_RUM && window.DD_RUM?.addAction('Copy is not supported', {link: shareUrl})
            window.console.error('Copy is not supported');
        }
    }, [shareUrl])

    const handleSharePress = useCallback(async () => {
        if (navigator.share) {
            // @ts-ignore
            window.DD_RUM && window.DD_RUM?.addAction('Shared recording link', {link: shareUrl})
            navigator.share({
                title: 'IKs book',
                url: shareUrl,
            }).catch((err) => {
                console.error(err)
                handleCopy();
            });
        } else {
            handleCopy();
        }
    }, [shareUrl, handleCopy])

    const closeCopyModal = useCallback(() => {
        setShowCopyModal(false);
    }, [])

    const handleSelect = useCallback((evt: { target: { select: () => void; }; }) => {
        evt.target.select()
    }, [])

    return (
        <>
            {
                showCopyModal && (
                    <Modal show={showCopyModal} onClose={closeCopyModal}>
                        {commonTxts.copiedToClipboard}
                    </Modal>
                )
            }
            <Button className={styles.ascRecBtn} disabled={disabled} onClick={handleSharePress}>
                {label}
                {subLabel && <span>{subLabel}</span>}
            </Button>
            {!disabled && <div className={styles.or}>{commonTxts.or}</div>}
            {
                !disabled && <div className={styles.fieldBox}>
                    <input type="text" readOnly value={shareUrl} onFocus={handleSelect} onMouseUp={() => false}/>
                    <button onClick={handleCopy}>{commonTxts.copy}</button>
                </div>
            }
        </>

    )
}
export default AskToRecordBtn;
