import React, {FC} from "react";

const Play: FC = () => (
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.5 13.4019C29.5 14.5566 29.5 17.4434 27.5 18.5981L5 31.5885C3 32.7432 0.499998 31.2998 0.499999 28.9904L0.5 3.00962C0.5 0.700216 3 -0.743159 5 0.411542L27.5 13.4019Z" />
    </svg>

)

export default Play;
