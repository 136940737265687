import React, {FC, memo, useCallback} from "react";
import styles from "./styles.module.scss";
import UserPhoto from "../UserPhoto";
import {flags} from "config/flags";
import {IRecord} from "../../types/IRecord";

interface IProps {
    list: IRecord[];
    title: string;
    onSelect: (narratorId: string) => void;
}

const Narrators: FC<IProps> = ({ title, list, onSelect }) => {
    const onPress = useCallback((id: string) => () => {
        onSelect(id);
    }, [onSelect])

    return (
        <div className={styles.narratorsBox}>
            <h1>{title}</h1>
            <div className={styles.narrators}>
                {
                    list.map(({ narratorAvatar, lang, id, full_audio }) => full_audio ? (
                        <div className={styles.border} key={id} onClick={onPress(id)}>
                            <UserPhoto
                                className={styles.narrator}
                                photo={narratorAvatar.src}
                                flag={flags[lang]}
                            />
                        </div>
                    ): null)
                }
            </div>
        </div>
    )
}

export default memo(Narrators);
