import React, {FC, ReactNode, useContext} from "react";
import stylesCommon from "styles/common.module.scss";
import Loading from "../icons/Loading";
import {AppContext} from "context/app";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import PageError from "../PageError";

interface IProps {
    children: ReactNode;
}

const App: FC<IProps> = ({ children }) => {
    const { hasAccess, hasRecordingAccess, isLoading } = useContext(AppContext);
    const {getLangTexts } = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);

    return (
        <>
            {!hasAccess && !hasRecordingAccess && !isLoading && (<PageError text={commonTxts.errorHappened} />)}
            {
                isLoading && (
                    <div className={stylesCommon.loading}><Loading/></div>
                )
            }
            {
                (hasAccess || hasRecordingAccess) && children
            }
        </>
    )
}
export default App;
