import React, {FC, useContext} from "react";
import styles from "./styles.module.scss";
import DotsAnimated from "../icons/DotsAnimated";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";

interface IProps {
    noText?: boolean;
}

const SavingOverlay: FC<IProps> = ({  noText }) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);

    return (
        <div className={styles.saving}>
            {!noText && <p>{commonTxts.saving}</p>}
            <DotsAnimated />
        </div>
    )

}

export default SavingOverlay;
