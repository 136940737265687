import React, {FC, memo, useContext} from "react";
import Title from "components/Title";

import styles from './styles.module.scss';
import {LangActionsContext} from "context/lang";
import {textsRecording} from "texts/recording";
import UserPhoto from "components/UserPhoto";
import LangSwitch from "components/BookLangSwitch";
import Button from "components/Button";
import {textsCommon} from "texts/common";
import {Lang} from "config/lang";
import useScrollToTop from "hooks/useScrollToTop";

interface IProps {
    recordLang: Lang;
    bookName: string;
    bookCover: string;
    bookLangs: Lang[];
    userAvatar?: string;
    changeRecordLang: (val: Lang) => void;
    onButtonPress: () => void;
}

const BookIntro: FC<IProps> = ({
   recordLang,
   bookName,
   bookCover,
   userAvatar,
   bookLangs,
   changeRecordLang,
   onButtonPress
}) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const recordTxts = getLangTexts(textsRecording);
    const commonTxts = getLangTexts(textsCommon);
    useScrollToTop();

    return (
        <div className={styles.wrap}>
            <Title className={styles.title}>{recordTxts.letChildReadStoryVoice}</Title>
            <div className={styles.cover}>
                <img
                    src={bookCover}
                    alt={bookName}
                />
                {userAvatar && (
                    <UserPhoto
                        className={styles.userPhoto}
                        photo={userAvatar}
                    />
                )}
            </div>
            <p className={styles.txt}>{recordTxts.narratorLang}</p>
            <LangSwitch selectedLang={recordLang} onLangChange={changeRecordLang} langs={bookLangs || []}/>
            <div className={styles.actionBtn}>
                <Button
                    fullwidth
                    onClick={onButtonPress}
                >
                    {commonTxts.start}
                </Button>
            </div>
        </div>
    )
}

export default memo(BookIntro);
