import {ICustomMap} from "../types/ICustomMap";
import {getAuth, signInAnonymously} from "firebase/auth";
import {getSmartObject} from "./firebase";
import {ISmartObject} from "../types/ISmartObject";

const auth = getAuth();

type TKeysType = '1' | '2' | '3';

export const accessKeysSet = (type: TKeysType): ICustomMap | null => {
    if (type === '1') return {
        secret: 'qrSecret',
        isBanned: 'qrIsBanned',
    };
    if (type === '2') return {
        secret: 'nfcSecret',
        isBanned: 'nfcIsBanned',
    };
    if (type === '3') return {
        secret: 'remoteRecSecret',
    };
    return null;
}

export const checkIfHasAccess = async (accessKey: string, isRemoteRec?: boolean): Promise<ISmartObject | false> => {
    try {
        await signInAnonymously(auth);
        const parts = accessKey.split('-');
        const type = parts[0];
        const id = parts[1];
        const secret = parts[2];

        if (!id || !type || !secret) return false;

        const keys = accessKeysSet(type as TKeysType);

        if (!keys) return false;

        const obj = await getSmartObject(id);

        if(!obj) return false;

        if(obj[keys.isBanned]) return false;
        if(obj[keys.secret] !== secret) return false;
        if(obj.isDeleted) return false;

        return {
            ownerAvatar: obj.ownerAvatar,
            recordingsList: obj.recordingsList,
            smartObjectBook: obj.smartObjectBook,
            type: obj.type,
            remoteRecSecret: obj.remoteRecSecret,
            id,
        }
    } catch {
        return false;
    }
}

export const checkIfHasRecordAccess = async (accessKey: string): Promise<ISmartObject | false> => {
    try {
        await signInAnonymously(auth);
        const parts = accessKey.split('-');
        const type = '3';
        const id = parts[0];
        const secret = parts[1];

        if (!id || !type || !secret) return false;

        const keys = accessKeysSet(type as TKeysType);

        if (!keys) return false;

        const obj = await getSmartObject(id);

        if(!obj) return false;

        if(obj[keys.secret] !== secret) return false;
        if(obj.isDeleted) return false;

        return {
            ownerAvatar: obj.ownerAvatar,
            recordingsList: obj.recordingsList,
            smartObjectBook: obj.smartObjectBook,
            type: obj.type,
            remoteRecSecret: obj.remoteRecSecret,
            id,
        }
    } catch {
        return false;
    }
}
