import {doc, getDoc, addDoc, updateDoc, collection, deleteDoc} from "firebase/firestore/lite";
import {db, storage} from "../firebase";
import {IBook} from "types/IBook";
import {Lang} from "config/lang";
import {IRecord} from "../types/IRecord";
import {ICustomMap} from "../types/ICustomMap";
import {ISmartObject} from "../types/ISmartObject";
import {ref, deleteObject, listAll} from "firebase/storage";

export const getSmartObject = async (id: string) => {
    if (!id) return null;
    try {
        const docRef = doc(db, "_smart_object", id);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
    } catch (err) {
        console.error(err);
        return null
    }
}

export const getSmartBook = async (id: string): Promise<IBook | null> => {
    if (!id) return null;
    try {
        const docRef = doc(db, "_smart_object_book", id);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data() as IBook;
        if (data?.lang) {
            data.lang = data?.lang?.map((val: string) => val.toLowerCase() as Lang);
        }
        if (data.recPageImages && data.recPageLanguages) {
            const pageLangs = data.recPageLanguages.reduce((acc: ICustomMap, { lang, pageNumber, text }) => {
                acc[pageNumber] = {
                    ...acc[pageNumber],
                    [lang]: text
                }
                return acc;
            }, {})
            data.recPages = data.recPageImages.map(({ pageNumber, img }) => {
                return {
                    img,
                    pageNum: pageNumber,
                    text: pageLangs[pageNumber] || {},
                }
            })
        }
        return data;
    } catch (err) {
        console.error(err);
        return null
    }
}

export const getRecord = async (id: string): Promise<IRecord | null> => {
    if (!id) return null;
    try {
        const docRef = doc(db, "_recordings", id);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data() as IRecord;
        if (data?.lang) {
            data.lang = data.lang.toLowerCase() as Lang;
        }
        return { ...data, id};
    } catch (err) {
        console.error(err);
        return null
    }
}

export const createEmptyRecordDoc = async () => {
    const docRef = await addDoc(collection(db, "_recordings"), {
        createdAt: new Date().toISOString(),
        narratorUserId: "",
        narratorVideoIntro: { src: null },
        lang: "",
        pages: [],
        smartObjectBook: "",
    });
    return docRef.id;
}


export const updateRecord = (recordId: string, data: IRecord) => {
    const recordRef = doc(db, "_recordings", recordId);
    return updateDoc(recordRef, data as ICustomMap);
}

export const updateSmartObject = (id: string, data: ISmartObject) => {
    const recordRef = doc(db, "_smart_object", id);
    return updateDoc(recordRef, data as ICustomMap);
}

export const deleteRecord = (recordId: string) => {
    return deleteDoc(doc(db, "_recordings", recordId));
}

export const deleteRecordFolder = (recordId: string) => {
    const desertRef = ref(storage, `_recordings/${recordId}`);
    return listAll(desertRef)
        .then((res) => {
            res.items.forEach(async (itemRef) => {
                await deleteObject(itemRef);
            });
        });
}
