import React, {FC} from "react";
import purify from "dompurify";
import styles from './styles.module.scss';
import cn from "classnames";
import Button from "../../Button";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import ReRecord from "../../icons/ReRecord";
import Play from "../../icons/Play";
import Pause from "../../icons/Pause";
import Modal from "../../Modal";
import SavingOverlay from "../../SavingOverlay";

import useRecording from "./useRecording";
import RecordTimeCount from "../../RecordTimeCount";
import StartRecordCountDown from "../../StartRecordCountDown";
import Close from "../../icons/Close";
import MicroPhone from "../../icons/MicroPhone";
import useScrollToTop from "hooks/useScrollToTop";

const Record: FC = () => {
    const {
        commonTxts,
        book,
        activePageNumber,
        activePage,
        isLoading,
        pagesWithRecords,
        activeText,
        imgFullScreen,
        isPlaying,
        currentRecord,
        recordTimer,
        recCountDown,
        err,
        toggleImgFullScreen,
        canGoNext,
        isRecording,
        handlePrevPress,
        handleNextPress,
        handleReRecord,
        handlePausePress,
        handlePlayPress,
        handleStopRecording,
        handleRecordPress,
        handleCloseErrModal,
        onCountDownDone,
    } = useRecording();

    useScrollToTop();

    if (!activePage) return null;

    return (
        <>
            <div className={styles.wrap}>
                {isLoading && (<SavingOverlay />)}
                <div className={cn(styles.cover, {[styles.fullScreen]: imgFullScreen})}>
                    <img src={activePage.img.src} alt='' onClick={isRecording || imgFullScreen ? undefined : toggleImgFullScreen}/>
                    <button onClick={toggleImgFullScreen}><Close /></button>
                </div>
                <div className={styles.progress}>
                    <div style={{width: `calc((100% / ${book?.recPages.length}) * ${activePageNumber})`}}/>
                    {
                        book?.recPages.map(({pageNum}) =>
                            <span
                                className={cn({
                                    [styles.active]: activePageNumber === pageNum,
                                    [styles.has]: pagesWithRecords?.includes(pageNum),
                                })}
                                key={pageNum}
                            />
                        )
                    }
                </div>
                <div className={styles.text} dangerouslySetInnerHTML={{__html: purify.sanitize(activeText)}}/>
                <div className={styles.recordBox}>
                    {
                        !isRecording && (
                            <div className={styles.recordMainBtns}>
                                <Button onClick={handlePrevPress} disabled={activePageNumber === 1}><ArrowLeft/></Button>
                                {
                                    currentRecord ? (
                                        <div className={styles.recBtns}>
                                            <Button red onClick={handleReRecord}><ReRecord/></Button>
                                            <Button blue className={styles.audioBtn}
                                                    onClick={isPlaying ? handlePausePress : handlePlayPress}>
                                                {isPlaying ? <Pause/> : <Play/>}
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button className={styles.recordBtn} red onClick={handleRecordPress}><MicroPhone />{commonTxts.record}</Button>
                                    )
                                }

                                <Button onClick={handleNextPress} disabled={!canGoNext}><ArrowRight/></Button>
                            </div>
                        )
                    }
                    {
                        isRecording && (
                            <div className={styles.recordingBtns}>
                                <RecordTimeCount dataRef={recordTimer} />
                                <Button className={styles.stopBtn} onClick={handleStopRecording}><span />{commonTxts.stop}</Button>
                            </div>
                        )
                    }
                </div>
            </div>
            <StartRecordCountDown dataRef={recCountDown} onDone={onCountDownDone} />
            {err && <Modal show={!!err} onClose={handleCloseErrModal}>{err}</Modal>}
        </>
    )
}

export default Record;
