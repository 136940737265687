import React, {FC} from "react";

const AddRecord: FC = () => (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.25 12V26.5" stroke="#00446B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M51.5 19.25H37" stroke="#00446B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.0119 27.5656C17.0111 27.9829 17.0342 28.3998 17.081 28.8143C17.2012 29.7658 17.5118 30.6817 17.9934 31.5046C18.475 32.3275 19.1172 33.0398 19.8799 33.5969C20.6401 34.1779 21.5092 34.5921 22.4327 34.8137C23.3563 35.0353 24.3145 35.0595 25.2476 34.8849C26.8831 34.5812 28.3598 33.6916 29.4135 32.3751C30.4672 31.0586 31.0291 29.4011 30.9988 27.6992C30.9988 24.1495 30.9988 21.8377 30.9988 18.2881C30.9966 17.9312 30.9761 17.5747 30.9374 17.22C30.7208 15.5713 29.9529 14.0505 28.7644 12.9165C27.576 11.7824 26.0405 11.1053 24.4194 11.0003H24.082C23.1496 10.9919 22.2249 11.1739 21.362 11.5357C20.4991 11.8975 19.7152 12.4318 19.0563 13.1074C18.3973 13.783 17.8764 14.5863 17.5241 15.4705C17.1717 16.3546 16.995 17.3018 17.0042 18.2567C17.0042 21.7722 16.9908 24.0502 17.0119 27.5656Z" fill="#00446B"/>
        <path d="M36 30C36 36.6274 30.6274 42 24 42C17.3726 42 12 36.6274 12 30" stroke="#00446B" strokeWidth="4" strokeLinecap="round"/>
        <line x1="24" y1="42" x2="24" y2="50" stroke="#00446B" strokeWidth="4" strokeLinecap="round"/>
    </svg>
)

export default AddRecord;
