import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {Lang} from "config/lang";

import {getRecord, getSmartBook} from "utils/firebase";
import {IBook} from "types/IBook";
import {localStorageKeys} from "config/localStorageKeys";
import {ICachedBookInfo, ICachedBooksInfo} from "types/ICachedBooksInfo";
import {cacheBookInfo} from "utils/cacheBooksInfo";
import {IRecord} from "types/IRecord";
import {Steps} from "./config";
import {AppActionsContext, AppContext} from "context/app";
import {ISmartObject} from "types/ISmartObject";

interface IContextData {
    recordLang?: Lang;
    book: IBook | null;
    record: IRecord | null;
    activeStep: Steps | undefined;
    isLoading: boolean;
    hasAccess: boolean;
    smartObject: ISmartObject | undefined;
    currentCachedRecdId: string | null;
    showSider: boolean;
}

interface IContextActions {
    changeRecordLang: (val: Lang) => void;
    setActiveStep: (step: Steps) => void,
    toggleShowSider: () => void,
    setDisableOpenSider: (val: boolean) => void,
    fetchRecord: (recordId: string) => Promise<void>,
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = {
    recordLang: undefined,
    book: null,
    record: null,
    activeStep: undefined,
    isLoading: false,
    hasAccess: false,
    smartObject: undefined,
    currentCachedRecdId: null,
    showSider: false,
};
const initActions: IContextActions = {
    changeRecordLang: () => null,
    setActiveStep: () => null,
    fetchRecord: () => new Promise(() => null),
    toggleShowSider: () => null,
    setDisableOpenSider: () => null,
};

export const Context = createContext(initData);
export const ActionsContext = createContext(initActions);

let callInit = true;

const ContextProvider: FC<IContextProviderProps> = ({children}) => {
    const [recordLang, setRecordLang] = useState<Lang>();
    const [book, setBook] = useState<IBook | null>(null);
    const [record, setRecord] = useState<IRecord | null>(null);
    const [activeStep, setActiveStep] = useState<Steps>();
    const [currentCachedRecdId, setCurrentCachedRecId] = useState<string | null>(null);

    const [showSider, setShowSider] = useState<boolean>(false);
    const [disableOpenSider, setDisableOpenSider] = useState<boolean>(false); // when recording in progress disabling open sider

    const { smartObject, isLoading, hasRecordingAccess, hasAccess } = useContext(AppContext);
    const { setIsLoading } = useContext(AppActionsContext);

    const fetchRecord = useCallback(async (recordId: string) => {
        const record = await getRecord(recordId);
        cacheBookInfo({recordId});
        setCurrentCachedRecId(recordId);
        setRecord(record);
    }, [])

    const getInitData = useCallback(async () => {

        if (smartObject) {
            const cachedBooksConfig: ICachedBooksInfo = JSON.parse(localStorage.getItem(localStorageKeys.booksConf) || '{}');
            const cachedBookInfo: ICachedBookInfo = cachedBooksConfig[smartObject.id];

            try {
                setIsLoading(true);
                const book = await getSmartBook(smartObject.smartObjectBook);
                setBook(book);
                const lang = book?.lang.find(val => val.toLowerCase() === cachedBookInfo?.recordLang?.toLowerCase()) || book?.lang[0]?.toLowerCase(); // define default selected book lang

                if (!book) {
                    window.console.error('Record book: book is not loaded');
                }

                if (!book?.lang) {
                    window.console.error('Record book: no book langs found');
                }

                setRecordLang(lang as Lang);

                if (cachedBookInfo?.recordId) {
                    await fetchRecord(cachedBookInfo.recordId);
                }
                if(cachedBookInfo?.step) {
                    setActiveStep(cachedBookInfo.step);
                }
            } finally {
                setIsLoading(false);
            }
        }
    }, [smartObject, fetchRecord, setIsLoading]);

    // fetch initial data;
    useEffect(() => {
        // call init only once not each time smartObject has been changed
        if ((hasRecordingAccess || hasAccess) && callInit) {
            getInitData();
            callInit = false;
        }
    }, [hasRecordingAccess, hasAccess, getInitData]);

    useEffect(() => {
        return () => {
            callInit = true
        }
    }, [])

    // caching narration lang when it changes
    useEffect(() => {
        if (recordLang) cacheBookInfo({recordLang});
    }, [recordLang]);

    const changeRecordLang = useCallback((val: Lang) => {
        setRecordLang(val);
    }, []);

    const handleChangeStep = useCallback((step: Steps) => {
        setActiveStep(step);
        cacheBookInfo({ step })
    }, [])

    const toggleShowSider = useCallback(() => {
        if (!disableOpenSider) {
            setShowSider(val => !val);
        }
    }, [disableOpenSider])

    return (
        <Context.Provider
            value={useMemo(
                () => ({
                    isLoading,
                    hasAccess: hasRecordingAccess || hasAccess,
                    recordLang,
                    book,
                    record,
                    activeStep,
                    smartObject,
                    currentCachedRecdId,
                    showSider,
                }),
                [smartObject, isLoading, hasRecordingAccess, recordLang, book, record, activeStep, currentCachedRecdId, hasAccess, showSider],
            )}
        >
            <ActionsContext.Provider value={useMemo(() => ({
                changeRecordLang,
                setActiveStep: handleChangeStep,
                fetchRecord,
                toggleShowSider,
                setDisableOpenSider,
            }), [changeRecordLang, fetchRecord, handleChangeStep, toggleShowSider, setDisableOpenSider])}>
                {children}
            </ActionsContext.Provider>
        </Context.Provider>
    );
};

export default ContextProvider;
