import React, {FC, useCallback, useContext} from "react";
import img1 from 'assets/man.png';
import img2 from 'assets/mom.png';

import styles from './styles.module.scss';
import Button from "../../Button";
import {useNavigate} from "react-router-dom";
import routes from "config/routes";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import useScrollToTop from "hooks/useScrollToTop";

interface IProps {
    onRemoteRecPress: () => void;
}

const RecordTypes: FC<IProps> = ({ onRemoteRecPress }) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const navigate = useNavigate();
    useScrollToTop();

    const handleNarrateSelf = useCallback(() => {
        navigate(routes.record)
    }, [navigate])

    return (
        <div className={styles.wrap}>
            <div className={styles.box}>
                <img src={img1} alt=''/>
                <Button onClick={onRemoteRecPress}>{commonTxts.askRecordRemote}</Button>
                <p>{commonTxts.askRecordRemoteDesc}</p>
            </div>

            <div className={styles.box}>
                <img src={img2} alt=''/>
                <Button onClick={handleNarrateSelf}>{commonTxts.narrateYourself}</Button>
                <p>{commonTxts.narrateYourselfDesc}.</p>
            </div>
        </div>
    )
}

export default RecordTypes;
