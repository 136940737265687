import React, {createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {ICustomMap} from 'types/ICustomMap';
import {availableTextLangs, Lang} from "../config/lang";

interface IContextData {
    lang: Lang;
    availableLangs: Lang[];
}

interface IContextActions {
    getLangTexts: (texts: ICustomMap) => ICustomMap;
    changeLang: (val: Lang) => void;
    setAvailableLangs: (langs: Lang[]) => void;
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = { lang: Lang.en, availableLangs: [] };
const initActions: IContextActions = {
    getLangTexts: () => ({}),
    changeLang: () => null,
    setAvailableLangs: () => [],
};

export const LangContext = createContext(initData);
export const LangActionsContext = createContext(initActions);

const LangContextProvider: FC<IContextProviderProps> = ({ children }) => {
    // @ts-ignore
    let browserLang = (navigator.language || navigator.userLanguage)?.split('-')[0];
    browserLang = availableTextLangs.includes(browserLang as Lang || '') ? browserLang : null;
    let cachedLang = localStorage.getItem('lang');
    cachedLang = availableTextLangs.includes(cachedLang as Lang || '') ? cachedLang : null;

    const [lang, setLang] = useState<Lang>(cachedLang || browserLang || availableTextLangs[0]);
    const [availableLangs, setAvailableLangs] = useState<Lang[]>(availableTextLangs);

    const changeLang = useCallback((val: Lang) => {
        setLang(val);
    }, []);

    useEffect(() => {
        localStorage.setItem('lang', lang);
    }, [lang]);

    const getLangTexts = useCallback((texts: ICustomMap) => {
        return texts[lang] || {};
    }, [lang])

    return (
        <LangContext.Provider
            value={useMemo(
                () => ({
                    lang,
                    availableLangs,
                }),
                [lang, availableLangs],
            )}
        >
            <LangActionsContext.Provider value={useMemo(() => ({ getLangTexts, changeLang, setAvailableLangs }), [changeLang, getLangTexts])}>
                {children}
            </LangActionsContext.Provider>
        </LangContext.Provider>
    );
};

export default LangContextProvider;
