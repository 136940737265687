import React, {FC, useContext} from "react";
import styles from "./styles.module.scss";
import img from "assets/lock.png";
import cn from "classnames";
import MathQuestion from "../../MathQuestion";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import useScrollToTop from "hooks/useScrollToTop";

interface IProps {
    onAnswer: () => void;
}

const LockQuestion: FC<IProps> = ({ onAnswer }) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    useScrollToTop();

    return (
        <>
            <div className={styles.info}>
                <p>{commonTxts.youReaInRecControl}</p>
                <img src={img} alt=''/>
            </div>
            <div className={cn(styles.askBox, {[styles.spaceTop]: false})}>
                <p>{commonTxts.answerToContinue}</p>
                <MathQuestion onFinnish={onAnswer}/>
            </div>
        </>
    )
}

export default LockQuestion;
