import React, {FC, MutableRefObject, useEffect} from "react";
import styles from "./styles.module.scss";
import MicroPhone from "../icons/MicroPhone";
import useTimer, {formatTime} from "../../hooks/useTimer";

interface IProps {
    dataRef: MutableRefObject<any>
}

const RecordTimeCount: FC<IProps> = ({ dataRef }) => {
    const {
        timer,
        handleStart,
        handleReset,
        handlePause,
    } = useTimer();

    useEffect(() => {
        if (dataRef) {
            dataRef.current = {
                ...(dataRef?.current || {}),
                start: handleStart,
                pause: handlePause,
                reset: handleReset,
            }
        }
    }, [dataRef, handleStart, handleReset, handlePause])

    useEffect(() => {
        if (dataRef) {
            dataRef.current = {
                ...(dataRef?.current || {}),
                duration: timer,
            }
        }
    }, [dataRef, timer])

    return (
        <div className={styles.box}>
            <MicroPhone/>
            <span className={styles.recordTime}>{formatTime(timer)}</span>
        </div>
    )
}

export default RecordTimeCount;
