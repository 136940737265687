import React, {FC, useContext} from "react";
import Curve from "components/Curve";
import Container from "components/Container";
import ContextProvider, {Context} from "./context";

import styles from './styles.module.scss';
import NarratorsView from "./components/NarratorsView";
import {View} from "./config";
import PlayBackView from "./components/PlayBackView";

const Home: FC = () => {
    const {isLoading, view} = useContext(Context);

    if (isLoading) return null;

    return (
        <>
            {view === View.narrators && <Curve className={styles.curve}/>}
            <Container>
                {view === View.narrators && <NarratorsView />}
                {view === View.playback && <PlayBackView />}
            </Container>
        </>
    )
}


const HomeApp: FC = () => (
    <ContextProvider><Home/></ContextProvider>
)

export default HomeApp;
