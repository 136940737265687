import enFlag from 'assets/flags/en.svg';
import uaFlag from 'assets/flags/ua.svg';
import nlFlag from 'assets/flags/nl.svg';
import deFlag from 'assets/flags/de.svg';


import {Lang} from "./lang";

const flags = {
    [Lang.en]: enFlag,
    [Lang.uk]: uaFlag,
    [Lang.nl]: nlFlag,
    [Lang.de]: deFlag,
}

export { flags };
