import React, {FC, memo, useCallback, useEffect, useState} from "react";
import {formatTime} from "hooks/useTimer";

import styles from "./styles.module.scss";
import {ICustomMap} from "types/ICustomMap";

interface IProps {
    totalTime: number;
}

const AudioTime: FC<IProps> = ({ totalTime }) => {
    const [playingTime, setPlayingTime] = useState<number>(0);

    const onPlaying = useCallback((evt: ICustomMap) => {
        setPlayingTime(Math.floor(evt.detail.seek));
    }, []);

    useEffect(() => {
        document.addEventListener('audio-time-update', onPlaying);
        return () => {
            document.removeEventListener('audio-time-update', onPlaying);
        }
    }, [onPlaying])

    return (
        <div className={styles.time}>
            {
                formatTime(playingTime)
            }
            &nbsp;/&nbsp;
            {
                formatTime(totalTime)
            }
        </div>
    )
}

export default memo(AudioTime);
