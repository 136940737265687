import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import 'styles/global.scss';
import './firebase';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import LangContextProvider from 'context/lang'
import AppContextProvider from "./context/app";
import AudioContextProvider from "./context/audio";
import {isProd} from "utils/isProd";

//logrocket service
import LogRocket from 'logrocket';
LogRocket.init('455xfh/bts1');


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <AppContextProvider>
        <LangContextProvider>
            <AudioContextProvider>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
            </AudioContextProvider>
        </LangContextProvider>
    </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.console.log('isProd', isProd);
