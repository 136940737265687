import React, {FC} from "react";

const ErrorCircled: FC = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.00001 2.33335C5.31811 2.33335 2.33334 5.31812 2.33334 9.00002C2.33334 12.6819 5.31811 15.6667 9.00001 15.6667C12.6819 15.6667 15.6667 12.6819 15.6667 9.00002C15.6667 5.31812 12.6819 2.33335 9.00001 2.33335ZM0.666672 9.00002C0.666672 4.39765 4.39763 0.666687 9.00001 0.666687C13.6024 0.666687 17.3333 4.39765 17.3333 9.00002C17.3333 13.6024 13.6024 17.3334 9.00001 17.3334C4.39763 17.3334 0.666672 13.6024 0.666672 9.00002ZM9.00001 4.83335C9.46024 4.83335 9.83334 5.20645 9.83334 5.66669V9.00002C9.83334 9.46026 9.46024 9.83335 9.00001 9.83335C8.53977 9.83335 8.16667 9.46026 8.16667 9.00002V5.66669C8.16667 5.20645 8.53977 4.83335 9.00001 4.83335ZM8.16667 12.3334C8.16667 11.8731 8.53977 11.5 9.00001 11.5H9.00834C9.46858 11.5 9.84167 11.8731 9.84167 12.3334C9.84167 12.7936 9.46858 13.1667 9.00834 13.1667H9.00001C8.53977 13.1667 8.16667 12.7936 8.16667 12.3334Z"
              fill="#FF563C"/>
    </svg>

)

export default ErrorCircled;
