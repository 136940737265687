import React, {FC, useCallback, useContext, useState} from "react";
import AddRecordSider from "components/AddRecordSider";
import styles from "./styles.module.scss";
import {ActionsContext, Context} from "../../context";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import Narrators from "components/Narrators";
import AddRecord from "components/icons/AddRecord";
import Burger from "components/icons/Burger";
import cn from "classnames";

const NarratorsView: FC = () => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const {book, narrators, bookNarrators, smartObject} = useContext(Context);
    const { handleSelectNarrator } = useContext(ActionsContext);

    const [openSider, setOpenSider] = useState<boolean>(false);

    const toggleSider = useCallback(() => {
        setOpenSider(val => !val);
    }, []);

    const hasNarrators = narrators?.some(({ full_audio }) => !!full_audio)

    // useEffect(() => {
    //     navigator.mediaDevices.getUserMedia({video: false, audio: true}).then((stream) => {
    //         navigator.mediaDevices.enumerateDevices()
    //             .then((devices) => {
    //                 devices.forEach((device) => {
    //                     console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
    //                 });
    //             })
    //             .catch((err) => {
    //                 console.error(`${err.name}: ${err.message}`);
    //             });
    //     }).catch((err) => {
    //         console.error(`you got an error: ${err}`)
    //     });
    // }, [])

    return (
        <div className={cn({[styles.siderOpened]: openSider})}>
            <button className={styles.navBtn} onClick={toggleSider}><Burger /></button>
            <AddRecordSider open={openSider} toggleSider={toggleSider} book={book} narratorsSize={narrators?.length} smartObject={smartObject} />
            {book && (
                <div className={styles.book}><img src={book.coverImg.src} alt={book.name}/></div>
            )}
            {hasNarrators ? (
                <Narrators title={commonTxts.family} list={narrators || []} onSelect={handleSelectNarrator}/>
            ) : (
                <div className={styles.addRecordButtonBox}>
                    <h1>{commonTxts.family}</h1>
                    <div className={styles.addRecBtnBorder}>
                        <button className={styles.addRecBtn} onClick={toggleSider}>
                            <AddRecord/>
                        </button>
                    </div>
                </div>
            )}
            {bookNarrators && (
                <Narrators title={commonTxts.narrators} list={bookNarrators}
                           onSelect={handleSelectNarrator}/>
            )}
        </div>
    )
}

export default NarratorsView;
