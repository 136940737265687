import React, {FC} from "react";
import stylesCommon from "styles/common.module.scss";
import Error from "../icons/Error";

interface IProps {
    text: string;
}

const PageError: FC<IProps> = ({ text }) => {
    return (
        <div className={stylesCommon.error}>
            <Error/>
            {text}
        </div>
    )
}

export default PageError;
