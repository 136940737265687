import React, {FC, memo, MouseEventHandler, ReactNode} from "react";

import styles from './styles.module.scss';
import cn from "classnames";
import Loading from "../icons/Loading";

interface IProps {
    type?: 'submit' | 'reset' | 'button' | undefined;
    disabled?: boolean | undefined;
    children: ReactNode;
    onClick?: MouseEventHandler | undefined;
    className?: string;
    fullwidth?: boolean;
    blue?: boolean;
    red?: boolean;
    loading?: boolean;
}

const Button: FC<IProps> = ({ type, disabled, loading, children, onClick, className, fullwidth, blue, red }) => (
    <button
        className={cn(styles.btn, {[className || '']: className, [styles.fullwidth]: fullwidth, [styles.blue]: blue, [styles.red]: red})}
        type={type}
        disabled={disabled}
        onClick={onClick}
    >
        {loading && <span className={styles.loader}><Loading /></span>}
        {children}
    </button>
)

export default memo(Button);
