import React, {FC, useCallback, useContext, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import ArrowLeft from "../icons/ArrowLeft";
import AppLangSwitch from "../AppLangSwitch";
import RemoteRecSetup from "./RemoteRecSetup";
import {IBook} from "types/IBook";
import {ISmartObject} from "types/ISmartObject";
import RecordTypes from "./RecordTypes";
import LockQuestion from "./LockQuestion";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import useMetaTheme from "hooks/useMetaTheme";
import useScrollToTop from "hooks/useScrollToTop";
import ManageRecords from "./ManageRecords";

enum View {
    question = 1,
    recTypes = 2,
    remoteRecSetup = 3,
    manageRecords = 4,
}

interface IProps {
    book: IBook | null;
    smartObject?: ISmartObject;
    narratorsSize?: number;
    onBackPress: () => void;
}

const LockedControl: FC<IProps> = ({ book, smartObject, narratorsSize, onBackPress }) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const [view, setView] = useState(View.question);

    const showTitle = view !== View.remoteRecSetup;

    useMetaTheme({ greenToBlue: true });
    useScrollToTop();

    const handleGoToRecTypes = useCallback(() => {
        setView(View.recTypes);
    }, [])

    const handleGoToRemoteRecSetup = useCallback(() => {
        setView(View.remoteRecSetup);
    }, [])

    const handleBack = useCallback(() => {
        if (view === View.recTypes || view === View.question) onBackPress();
        if (view === View.remoteRecSetup) setView(View.recTypes);
        if (view === View.manageRecords) setView(View.remoteRecSetup);
    }, [onBackPress, view]);

    const onManageRecPress = useCallback(() => {
        setView(View.manageRecords);
    }, [])

    const title = useMemo(() => {
        if (view === View.recTypes ) return commonTxts.chooseRecType;
        if (view === View.manageRecords ) return commonTxts.manageRecords;
         return commonTxts.parentsOnly;
    }, [view, commonTxts])

    return (
        <div className={styles.wrap}>
            { view === View.remoteRecSetup && <div className={styles.round} />}
            <header>
                <button className={styles.back} onClick={handleBack}><ArrowLeft/></button>
                {
                    showTitle && <h3>{title}</h3>
                }
                {view === View.question && <AppLangSwitch/>}
            </header>
            {
                view === View.question && (
                    <LockQuestion onAnswer={handleGoToRecTypes} />
                )
            }
            {
                view === View.recTypes && (
                    <RecordTypes onRemoteRecPress={handleGoToRemoteRecSetup} />
                )
            }
            {
                view === View.remoteRecSetup && (
                    <RemoteRecSetup book={book} narratorsSize={narratorsSize} smartObject={smartObject} onManageRecPress={onManageRecPress} />
                )
            }
            {
                view === View.manageRecords && (
                    <ManageRecords />
                )
            }
        </div>
    )
}

export default LockedControl;
