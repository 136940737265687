import React, {FC, memo, ReactNode} from "react";
import parrot from 'assets/parrot.png';
import styles from './styles.module.scss';
import cn from "classnames";
import Close from "../icons/Close";

interface IProps {
    open?: boolean;
    message: ReactNode;
    children?: ReactNode;
    showOnHover?: boolean;
    onClose?: () => void;
    position?: 'left' | 'right' | 'bottom' | 'top';
}

const Tooltip: FC<IProps> = ({ open, message, position, showOnHover, children, onClose }) => {
    const pos = position || 'bottom';
    return (
        <div className={cn(styles.tooltip, {[styles.showOnHover]: showOnHover})}>
            <div
                className={cn(
                    styles.message,
                    [styles[pos]],
                    {[styles.visible]: open},
                )}
            >
                {message}
                <img src={parrot} alt='' />
                {!!onClose && <button className={styles.btn} onClick={onClose}><Close /></button>}
            </div>
            {children}
        </div>
    )
}


export default memo(Tooltip);
