import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Container from 'components/Container';
import Curve from 'components/Curve';
import styles from './styles.module.scss';
import { LangActionsContext } from 'context/lang';

import ContextProvider, { ActionsContext, Context } from './context';
import { Steps } from './config';
import BookIntro from './BookIntro';
import Recording from 'components/RecordBook/Recording';
import NarratorIntro from './NarratorIntro';
import Review from './Review';
import Save from './Save';
import SideNav from '../SideNav';
import { textsRecording } from 'texts/recording';
import cn from 'classnames';
import Burger from '../icons/Burger';

interface IProps {
    withBookInto?: boolean;
    hideHomeButton?: boolean;
}

const RecordBook: FC<IProps> = ({ withBookInto, hideHomeButton }) => {
    const { getLangTexts } = useContext(LangActionsContext);
    const recordingTxts = getLangTexts(textsRecording);

    const { isLoading, hasAccess, activeStep, recordLang, book, smartObject, record, showSider } = useContext(Context);
    const { changeRecordLang, setActiveStep, toggleShowSider } = useContext(ActionsContext);

    const isRecordings = activeStep === Steps.recording;
    const isReview = activeStep === Steps.review;
    const isSave = activeStep === Steps.save;

    useEffect(() => {
        if (!activeStep && !isLoading) {
            let step = withBookInto ? Steps.bookIntro : Steps.narratorIntro;
            setActiveStep(step);
        }
    }, [withBookInto, setActiveStep, isLoading, activeStep]);

    const goToNarratorIntro = useCallback(() => {
        setActiveStep(Steps.narratorIntro);
    }, [setActiveStep]);

    const isAvatarCompleted = !!record?.narratorAvatar?.src;
    const isRecordingCompleted = record?.pages?.length === book?.recPages?.length;

    const goToAvatar = useCallback(() => {
        if (isAvatarCompleted) {
            setActiveStep(Steps.narratorIntro);
        }
    }, [isAvatarCompleted, setActiveStep]);

    const goToRecordings = useCallback(() => {
        if (isRecordingCompleted || isAvatarCompleted) {
            setActiveStep(Steps.recording);
        }
    }, [isAvatarCompleted, isRecordingCompleted, setActiveStep]);

    const goToReview = useCallback(() => {
        if (isRecordingCompleted && isAvatarCompleted) {
            setActiveStep(Steps.review);
        }
    }, [isAvatarCompleted, isRecordingCompleted, setActiveStep]);

    const nav = useMemo(
        () => [
            {
                label: recordingTxts.yourAvatar,
                showCheckIcon: isAvatarCompleted,
                onPress: goToAvatar,
            },
            {
                label: recordingTxts.bookRecording,
                showCheckIcon: isRecordingCompleted,
                onPress: goToRecordings,
            },
            {
                label: recordingTxts.review,
                showCheckIcon: isRecordingCompleted,
                onPress: goToReview,
            },
            {
                label: recordingTxts.upload,
                showCheckIcon: false,
            },
        ],
        [recordingTxts, isRecordingCompleted, isAvatarCompleted, goToRecordings, goToAvatar, goToReview],
    );

    if (isLoading) return null;
    // we have 5 record done already and it is new recording (not editing) or user in a way to finish the last narration
    // if (smartObject?.recordingsList?.length === 5 && !currentCachedRecdId) return <PageError text={recordingTxts.maxRecordsReached} />
    return (
        <>
            {!isRecordings && !isReview && <Curve />}
            <Container>
                {!isReview && !isSave && hasAccess && (
                    <>
                        <button className={cn(styles.btn, { [styles.burgerWhite]: isRecordings })} onClick={toggleShowSider}>
                            <Burger />
                        </button>
                        <SideNav nav={nav} show={showSider} toggleSider={toggleShowSider} />
                    </>
                )}
                {activeStep === Steps.bookIntro && withBookInto && recordLang && book && (
                    <BookIntro
                        recordLang={recordLang}
                        changeRecordLang={changeRecordLang}
                        bookName={book.name}
                        bookCover={book.coverImg.src}
                        bookLangs={book.lang}
                        userAvatar={smartObject?.ownerAvatar.src}
                        onButtonPress={goToNarratorIntro}
                    />
                )}
                {activeStep === Steps.narratorIntro && <NarratorIntro />}
                {activeStep === Steps.recording && <Recording />}
                {activeStep === Steps.review && <Review />}
                {activeStep === Steps.save && <Save hideHomeButton={hideHomeButton} />}
            </Container>
        </>
    );
};

const RecordApp: FC<IProps> = props => (
    <ContextProvider>
        <RecordBook {...props} />
    </ContextProvider>
);

export default RecordApp;
