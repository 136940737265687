import React, {FC} from "react";
import styles from './styles.module.scss';
import cn from "classnames";
import {IBook} from "types/IBook";
import {ISmartObject} from "types/ISmartObject";
import LockedControl from "../LockedControl";

interface IProps {
    open?: boolean;
    book: IBook | null;
    narratorsSize?: number;
    smartObject?: ISmartObject;
    toggleSider: () => void;
}

const AddRecordSider: FC<IProps> = ({ open, book, narratorsSize, smartObject, toggleSider }) => (
    <aside className={cn(styles.sideNav, {[styles.open]: open})}>
        {open && <LockedControl book={book} narratorsSize={narratorsSize} smartObject={smartObject} onBackPress={toggleSider} />}
    </aside>
)

export default AddRecordSider;
