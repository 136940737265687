import React, {FC} from "react";

const UserPlus: FC = () => (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.6667 56.3208V50.9875C42.6667 48.1585 41.5429 45.4454 39.5425 43.445C37.5421 41.4446 34.829 40.3208 32 40.3208H13.3333C10.5044 40.3208 7.79125 41.4446 5.79086 43.445C3.79047 45.4454 2.66667 48.1585 2.66667 50.9875V56.3208" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.6667 29.6541C28.5577 29.6541 33.3333 24.8785 33.3333 18.9875C33.3333 13.0964 28.5577 8.3208 22.6667 8.3208C16.7756 8.3208 12 13.0964 12 18.9875C12 24.8785 16.7756 29.6541 22.6667 29.6541Z" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M53.3333 21.6541V37.6541" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M61.3333 29.6541H45.3333" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default UserPlus;
