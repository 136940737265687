import React, {FC} from "react";

const ReRecord: FC = () => (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M38.0011 2.30176C39.1056 2.30176 40.0011 3.19719 40.0011 4.30176V14.3028C40.0011 15.4074 39.1056 16.3028 38.0011 16.3028H28C26.8954 16.3028 26 15.4074 26 14.3028C26 13.1982 26.8954 12.3028 28 12.3028H36.0011V4.30176C36.0011 3.19719 36.8965 2.30176 38.0011 2.30176Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.07083e-06 20.3018C3.07083e-06 19.1972 0.895434 18.3018 2 18.3018H12.0011C13.1056 18.3018 14.0011 19.1972 14.0011 20.3018C14.0011 21.4063 13.1056 22.3018 12.0011 22.3018H4V30.3028C4 31.4074 3.10457 32.3028 2 32.3028C0.895434 32.3028 3.07083e-06 31.4074 3.07083e-06 30.3028V20.3018Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M23.5178 4.81096C21.4577 4.20398 19.2811 4.13857 17.19 4.62052C15.0988 5.10251 13.1578 6.11705 11.5494 7.5732C9.94087 9.02956 8.71727 10.8805 7.99652 12.9552C7.63405 13.9986 6.49436 14.5506 5.45096 14.1881C4.40756 13.8256 3.85556 12.6859 4.21803 11.6425C5.1571 8.93939 6.75449 6.51857 8.86481 4.60795C10.9754 2.69713 13.53 1.3592 16.2917 0.72271C19.0534 0.0861885 21.9288 0.172764 24.6483 0.97404C27.3573 1.77223 29.8208 3.25274 31.8121 5.27446L39.3829 12.5209C40.1809 13.2847 40.2086 14.5507 39.4448 15.3486C38.6811 16.1466 37.415 16.1743 36.6171 15.4105L29.0244 8.1431C29.0092 8.12864 28.9944 8.11395 28.9797 8.09902C27.4577 6.54789 25.5781 5.41799 23.5178 4.81096ZM0.555178 19.2502C1.31895 18.4522 2.58497 18.4245 3.38293 19.1883L10.9757 26.4557C10.9908 26.4701 11.0056 26.4848 11.0203 26.4998C12.5423 28.0509 14.4219 29.1808 16.4822 29.7878C18.5423 30.3948 20.7189 30.4602 22.81 29.9783C24.9012 29.4963 26.8422 28.4817 28.4506 27.0256C30.0591 25.5692 31.2827 23.7183 32.0035 21.6436C32.366 20.6002 33.5056 20.0482 34.549 20.4107C35.5924 20.7731 36.1445 21.9128 35.782 22.9562C34.8429 25.6594 33.2455 28.0802 31.1352 29.9908C29.0246 31.9016 26.47 33.2396 23.7083 33.8761C20.9466 34.5126 18.0712 34.426 15.3517 33.6247C12.6427 32.8266 10.1792 31.346 8.18785 29.3243L0.617078 22.0779C-0.180876 21.3141 -0.20859 20.0481 0.555178 19.2502Z"
              fill="white"/>
    </svg>

)

export default ReRecord;
