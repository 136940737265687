import React, {FC} from "react";

const Pause: FC = () => (
    <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="7" height="35" rx="3.5" fill="white" stroke="white"/>
        <rect x="23.5" y="0.5" width="7" height="35" rx="3.5" fill="white" stroke="white"/>
    </svg>

)

export default Pause;
