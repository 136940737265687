import {useEffect} from "react";

interface IProps {
    bluToGreen?: boolean;
    greenToBlue?: boolean;
}

const blue = '#e9f9f4';
const green = '#f2fde8';

// In mob browser with top control bar as on ios, change theme color depends on blue or green content at the top of screen like blue curve
const useMetaTheme = ({bluToGreen, greenToBlue}: IProps) => {
    useEffect(() => {
        const theme = document.querySelector('meta[name="theme-color"]');

        if (bluToGreen) {
            theme?.setAttribute('content', blue);
        }
        if (greenToBlue) {
            theme?.setAttribute('content', green);
        }
        return () => {
            if (bluToGreen) {
                theme?.setAttribute('content', green);
            }
            if (greenToBlue) {
                theme?.setAttribute('content', blue);
            }
        }
    }, [bluToGreen, greenToBlue]);

    return null
}

export default useMetaTheme;
