import React, {FC, memo, ReactNode} from "react";
import parrot from 'assets/parrot.png';
import styles from "./styles.module.scss";
import Close from "../icons/Close";


interface IProps {
    show: boolean;
    children: ReactNode;
    onClose: () => void,
}

const Modal: FC<IProps> = ({ show, children, onClose }) => {
    return (
        <section className={styles.modal}>
            <div className={styles.content}>
                <button className={styles.btn} onClick={onClose}><Close /></button>
                <img className={styles.img} src={parrot} alt='' />
                {children}
            </div>
        </section>
    )
}

export default memo(Modal);
