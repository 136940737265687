import React, {ChangeEvent, FC, memo} from "react";

import cn from "classnames";
import UserPlus from "components/icons/UserPlus";

import styles from './styles.module.scss';
import Pencil from "../icons/Pencil";
import Delete from "../icons/Delete";

interface IProps {
    className?: string;
    width?: number;
    height?: number;
    photo: string | undefined;
    flag?: string;
    handleChangePhoto?: (data: ChangeEvent<HTMLInputElement>) => void;
    handleRemove?: () => void;
}

const UserPhoto:FC<IProps> = ({
    photo,
    className,
    width,
    height,
    flag,
    handleChangePhoto,
    handleRemove
}) => {
    const isAddPhoto: boolean = !photo && !!handleChangePhoto;
    const showPencil: boolean = !!photo && !!handleChangePhoto;

    return (
        <div className={cn(styles.photoBox, {[styles.addPhoto]: isAddPhoto, [className || '']: className })} style={{ width, height }}>
            {
                showPencil && (
                    <span className={styles.pencil}><Pencil /></span>
                )
            }
            {
                handleChangePhoto && <input type="file" size={99999} accept='image/*' onChange={handleChangePhoto} />
            }
            { flag && <span className={styles.flag}><img src={flag} alt='' /></span>}
            {
                handleRemove && <button className={styles.delete} onClick={handleRemove}><Delete /></button>
            }
            {photo && <img src={photo} alt='' />}
            {
                isAddPhoto && (
                    <UserPlus />
                )
            }
        </div>
    )
}

export default memo(UserPhoto);
