import React, {ChangeEvent, FC, useCallback, useContext, useState} from "react";
import styles from "./styles.module.scss";
import UserPhoto from "../../UserPhoto";
import userPlaceHolderImg from "assets/user-placeholder.png";
import BookLangSwitch from "../../BookLangSwitch";
import Button from "../../Button";
import AskToRecordBtn from "../../AskToRecordBtn";
import Info from "../../icons/Info";
import {IBook} from "types/IBook";
import {resizeFile} from "utils/cropImg";
import {toBase64} from "utils/fileToBase64";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../../../firebase";
import {updateSmartObject} from "utils/firebase";
import {ISmartObject} from "types/ISmartObject";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import SavingOverlay from "../../SavingOverlay";
import useMetaTheme from "hooks/useMetaTheme";
import Tooltip from "../../Tooltip";
import useScrollToTop from "hooks/useScrollToTop";

interface IProps {
    book: IBook | null;
    smartObject?: ISmartObject;
    narratorsSize?: number;
    onManageRecPress: () => void;
}


const RemoteRecSetup: FC<IProps> = ({ book, smartObject, narratorsSize, onManageRecPress }) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    useMetaTheme({ bluToGreen: true });
    useScrollToTop();

    const [photo, setPhoto] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const showAddPhotoOnRecConfig = localStorage.getItem(localStorageKeys.showAddPhotoOnRecConfig) ? localStorage.getItem(localStorageKeys.showAddPhotoOnRecConfig) === 'true' : true;

    const [showTooltip, setShowTooltip] = useState<boolean>(true);

    const userAvatar = smartObject?.ownerAvatar.src;

    const handlePhotoChange = useCallback(async (data: ChangeEvent<HTMLInputElement>) => {
        const file = data?.target?.files?.[0];
        if (file && smartObject) {
            try {
                setIsLoading(true);
                const resizedFile = await resizeFile(file);
                const b64 = await toBase64(resizedFile);
                setPhoto(b64);

                const metadata = {
                    contentType: 'image/jpeg',
                };
                const storageRef = ref(storage, `_smart_object/${smartObject.id}/ownerAvatar`);
                const snap = await uploadBytes(storageRef, resizedFile, metadata);
                const imgUrl = await getDownloadURL(snap.ref);
                await updateSmartObject(smartObject.id, { ownerAvatar: { src: imgUrl } } as ISmartObject);
            } finally {
                setIsLoading(false);
            }
        }
    }, [smartObject]);

    const recordsLen = (narratorsSize || 0) + 1;
    const disableShareBtn = isLoading || !(photo || userAvatar) || recordsLen > 500; 

    const hideTooltip = useCallback(() => {
        setShowTooltip(false);
        // localStorage.setItem(localStorageKeys.showAddPhotoOnRecConfig, 'false');
    }, [])

    return (
        <section onClick={hideTooltip}>
            {isLoading && <SavingOverlay />}
            <div className={styles.preview}>
                <div className={styles.imgs}>
                    <img className={styles.cover} src={book?.coverImg.src} alt='' />
                    <UserPhoto photo={photo || userAvatar || userPlaceHolderImg} className={styles.photo} />
                </div>
                {
                    book && <BookLangSwitch className={styles.langs} langs={book.lang} selectedLang={book.lang[0]} onLangChange={() => {}} />
                }
                <Button className={styles.btn}>{commonTxts.recordBook}</Button>
            </div>
            <h2 className={styles.previewTitle}>{commonTxts.familyMemberWillReceiveThis}</h2>
            <div className={styles.addPhotoBox}>
                <Tooltip open={showTooltip} message={commonTxts.addPhotoHelpText} position='top' onClose={hideTooltip}>
                    <UserPhoto className={styles.addPhoto} handleChangePhoto={handlePhotoChange} photo={photo || userAvatar} />
                </Tooltip>
            </div>
            {
                //smartObject && <AskToRecordBtn smartObject={smartObject} label={`${commonTxts.ascToRec} (${recordsLen}/5)`} subLabel={commonTxts.sendRecLink} disabled={disableShareBtn} />
            
                smartObject && <AskToRecordBtn smartObject={smartObject} label={`${commonTxts.ascToRec}`} subLabel={commonTxts.sendRecLink} disabled={disableShareBtn} />
            }
            <p className={styles.txt}><Info /> {commonTxts.maxNarratorsMsg}</p>
            {/*<Button className={styles.manageRecords} onClick={onManageRecPress}>{commonTxts.manageRecords}</Button>*/}
        </section>
    )
}

export default RemoteRecSetup;
