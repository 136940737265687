import React, {
    createContext,
    FC,
    MutableRefObject,
    ReactNode,
    useCallback, useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {Howl} from "howler";
import {IRecord} from "../types/IRecord";
import {ICustomMap} from "../types/ICustomMap";

interface IContextData {
    sound: Howl | undefined;
    withBell: boolean;
    soundIntroOffset: number;
    soundRangesRef: MutableRefObject<ICustomMap>;
}

interface IContextActions {
    setupSound: (record: IRecord) => void;
    setWithBell: (val: boolean) => void;
    toggleSwitchBell: () => void;
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = {
    sound: undefined,
    withBell: true,
    soundIntroOffset: 0,
    soundRangesRef: {
        current: {}
    }
};
const initActions: IContextActions = {
    setupSound: () => null,
    setWithBell: () => null,
    toggleSwitchBell: () => null,
};

export const AudioContext = createContext(initData);
export const AudioActionsContext = createContext(initActions);

const AudioContextProvider: FC<IContextProviderProps> = ({children}) => {
    const [record, setRecord] = useState<IRecord | null>(null);
    const [soundWitBell, setSoundWitBell] = useState<string>();
    const [soundNoBell, setSoundNoBell] = useState<string>();
    // audio with bell has extra introduction, need those time to calculate pages switching for slider and audios switching
    const [soundIntroOffset, setSoundIntroOffset] = useState<number>(0);
    const [sound, setSound] = useState<Howl>();
    const [withBell, setWithBell] = useState<boolean>(false);
    const soundRangesRef = useRef<ICustomMap>({});

    const setSoundRanges = useCallback(() => {
        if (!record) return;
        soundRangesRef.current = record.pages.reduce((acc: ICustomMap, page) => {
            let start = record.pages.reduce((sum, {pageNum, duration}) => {
                if (pageNum < page.pageNum) sum += duration;
                return sum;
            }, 0)
            let end = (record.pages.find(({pageNum}) => pageNum === page.pageNum)?.duration || 0) + start;

            start = start > 0 && withBell ? start + soundIntroOffset : start;
            end = withBell ? end + soundIntroOffset : end;

            acc[`${start}-${end}`] = page.pageNum
            return acc;
        }, {});
    }, [record, withBell, soundIntroOffset])

    const setupSound = useCallback((record: IRecord) => {
        const { full_transition_audio, full_audio, full_transition_audio_intro_offset: introOffset } = record;
        const audioWithBellSrc = full_transition_audio?.src;
        const audioNoBellSrc = full_audio?.src;
        if (!audioWithBellSrc || !audioNoBellSrc) return;
        const soundInstance: Howl = new Howl({
            src: [withBell ? audioWithBellSrc : audioNoBellSrc],
            html5: true,
        })
        setSoundWitBell(audioWithBellSrc);
        setSoundNoBell(audioNoBellSrc);
        setSound(soundInstance);
        setSoundIntroOffset(introOffset || 0);
        setRecord(record);
        soundInstance.play();
    }, [withBell])

    useEffect(() => {
        if (record) {
            setSoundRanges();
        }
    }, [record, setSoundRanges])

    useEffect(() => {
        if (sound && navigator && navigator.mediaSession) {
            navigator.mediaSession.setActionHandler('play', () => sound.play())
            navigator.mediaSession.setActionHandler('pause', () => sound.pause())
        }
    }, [sound])

    const toggleSwitchBell = useCallback(() => {
        setWithBell(val => {
            const hasBell = !val;
            sound?.pause();
            let seekPos = sound?.seek() || 0;
            seekPos = hasBell ? seekPos + soundIntroOffset / 1000 : seekPos - soundIntroOffset / 1000;
            seekPos = seekPos >= 0 ? seekPos : 0;
            sound?.unload();

            const newSoundSrc = hasBell ? soundWitBell : soundNoBell;
            if (newSoundSrc) {
                const soundInstance: Howl = new Howl({
                    src: [newSoundSrc],
                    html5: true,
                })
                setSound(soundInstance);
                soundInstance.play();
                soundInstance.seek(seekPos);
            }
            setSoundRanges();
            return hasBell;
        });
    }, [sound, soundWitBell, soundNoBell, soundIntroOffset, setSoundRanges]);

    return (
        <AudioContext.Provider
            value={useMemo(
                () => ({
                    sound,
                    withBell,
                    soundIntroOffset,
                    soundRangesRef
                }),
                [sound, withBell, soundIntroOffset],
            )}
        >
            <AudioActionsContext.Provider value={useMemo(() => ({
                setupSound,
                setWithBell,
                toggleSwitchBell
            }), [
                setupSound,
                setWithBell,
                toggleSwitchBell
            ])}>
                {children}
            </AudioActionsContext.Provider>
        </AudioContext.Provider>
    );
};

export default AudioContextProvider;
