import React, {FC, memo} from "react";

import styles from './styles.module.scss';
import Close from "../icons/Close";

import logo from 'assets/logo.png';

import cn from "classnames";
import CheckCircle from "../icons/CheckCircle";
import AppLangSwitch from "../AppLangSwitch";

interface INav {
    label: string;
    showCheckIcon: boolean;
    onPress?: () => void;
}

interface IProps {
    toggleSider: () => void;
    show?: boolean;
    nav?: INav[];
}

const SideNav: FC<IProps> = ({show, toggleSider, nav}) => {
    return (
        <>
            <aside className={cn(styles.sideNav, {[styles.open]: show})}>
                <header>
                    <button className={styles.btn} onClick={toggleSider}><Close/></button>
                    <div className={styles.logo}><img src={logo} alt='IKs book' /></div>
                    <AppLangSwitch />
                </header>
                <ul className={styles.menu}>
                    {
                        nav?.map(({label, showCheckIcon, onPress}) => (
                            <li key={label} onClick={() => {
                                if (onPress) onPress();
                                toggleSider();
                            }}>
                                {showCheckIcon ? <CheckCircle/> : <span/>}
                                {label}
                            </li>
                        ))
                    }
                </ul>
            </aside>
        </>
    )
}

export default memo(SideNav);
