import React, {FC} from "react";

const Burger: FC = () => (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 18L20 18" stroke="#00446B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 10L20 10" stroke="#00446B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 2L20 2" stroke="#00446B" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Burger;
