import React, {FC, useCallback, useContext, useState} from "react";

import styles from './styles.module.scss';
import {LangActionsContext, LangContext} from "context/lang";
import {Lang} from "config/lang";
import {flags} from "config/flags";
import Triangle from "../icons/Triangle";
import cn from "classnames";

const AppLangSwitch: FC = () => {
    const {lang, availableLangs} = useContext(LangContext);
    const {changeLang} = useContext(LangActionsContext);
    const [showLangs, setShowLangs] = useState<boolean>(false);

    const toggleShowLangs = useCallback(() => {
        setShowLangs(val => !val);
    }, [])

    const handleSelectLang = useCallback((val: Lang) => () => {
        changeLang(val);
        toggleShowLangs();
    }, [changeLang, toggleShowLangs])

    return (
        <div className={styles.langs}>
            <div className={styles.selected}>
                <button onClick={toggleShowLangs}>
                    <img src={flags[lang]} alt={lang}/>
                    <Triangle/>
                </button>
            </div>
            <ul className={cn(styles.expand, {[styles.open]: showLangs})}>
                {
                    availableLangs.map((val) => {
                        if (lang === val) return null;
                        return <li key={val} onClick={handleSelectLang(val)}><img src={flags[val]} alt={val}/></li>
                    })
                }
            </ul>
        </div>
    )
}

export default AppLangSwitch;
