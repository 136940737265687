import React, {FC} from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import useMetaTheme from "hooks/useMetaTheme";

interface IProps {
    height?: number;
    className?: string;
}

const Curve:FC<IProps> = ({ height, className }) => {
    useMetaTheme({ bluToGreen: true });

    return (
        <section className={cn(styles.curve, {[className || '']: className})} style={{height}} />
    )
}

export default Curve;
