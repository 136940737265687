import React, {createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {checkIfHasAccess, checkIfHasRecordAccess} from "utils/accessToApp";
import {ISmartObject} from "types/ISmartObject";
import {localStorageKeys} from "config/localStorageKeys";
import {getSmartObject} from "../utils/firebase";

const blue = '#e9f9f4';

interface IContextData {
    hasAccess: boolean;
    hasRecordingAccess: boolean;
    isLoading: boolean;
    smartObject: ISmartObject | undefined;
}

interface IContextActions {
    setIsLoading: (val: boolean) => void;
    fetchSmartObject: (id: string) => void;
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = {
    hasAccess: false,
    hasRecordingAccess: false,
    isLoading: true,
    smartObject: undefined,
};
const initActions: IContextActions = {
    setIsLoading: () => {},
    fetchSmartObject: () => {},
};

export const AppContext = createContext(initData);
export const AppActionsContext = createContext(initActions);

const accessKey = localStorage.getItem(localStorageKeys.appAccessKey);
const recAccessKey = localStorage.getItem(localStorageKeys.recAccessKey);
let smartObjectId = accessKey ? accessKey.split('-')[1] : null;
smartObjectId = recAccessKey ? recAccessKey.split('-')[0] : smartObjectId;

const AppContextProvider: FC<IContextProviderProps> = ({children}) => {
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [hasRecordingAccess, setHasRecordingAccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [smartObject, setSmartObject] = useState<ISmartObject>();

    const checkAccess = useCallback(async () => {
        if (smartObjectId && (accessKey || recAccessKey)) {
            setIsLoading(true);
            try {
                let smartObject = accessKey ? await checkIfHasAccess(accessKey) : null;
                smartObject = recAccessKey ? await checkIfHasRecordAccess(recAccessKey) : smartObject;

                if (smartObject) {
                    setSmartObject(smartObject)
                    setHasAccess(!recAccessKey); // true if not remoter recording
                    setHasRecordingAccess(!!recAccessKey);
                } else {
                    setHasAccess(false);
                    setHasRecordingAccess(false);
                    setIsLoading(false);
                }
            } catch {
                setIsLoading(false);
            }
        } else {
            setHasAccess(false);
            setIsLoading(false);
            setHasRecordingAccess(false);
        }
    }, []);

    const fetchSmartObject = useCallback(async (id: string) => {
        const obj = await getSmartObject(id) || {};
        setSmartObject({
            ownerAvatar: obj.ownerAvatar,
            recordingsList: obj.recordingsList,
            smartObjectBook: obj.smartObjectBook,
            type: obj.type,
            id,
        })
    }, []);

    useEffect(() => {
        checkAccess();
    }, [checkAccess]);

    useEffect(() => {
        if (!isLoading) {
            const element = document.getElementById('load');
            if (element) element.style.display = 'none';
            const theme = document.querySelector('meta[name="theme-color"]');
            theme?.setAttribute('content', blue);
        }
    }, [isLoading])


    return (
        <AppContext.Provider
            value={useMemo(
                () => ({
                    hasAccess,
                    hasRecordingAccess,
                    isLoading,
                    smartObject
                }),
                [smartObject, hasAccess, isLoading, hasRecordingAccess],
            )}
        >
            <AppActionsContext.Provider value={useMemo(
                () => ({
                    setIsLoading,
                    fetchSmartObject,
                }), [setIsLoading, fetchSmartObject])}>
                {children}
            </AppActionsContext.Provider>
        </AppContext.Provider>
    );
};

export default AppContextProvider;
