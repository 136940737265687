import React, {FC, useCallback, useContext, useEffect} from "react";

import styles from './styles.module.scss';
import Button from "components/Button";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import ArrowLeft from "../../icons/ArrowLeft";
import {ActionsContext, Context} from "../context";
import {Steps} from "../config";
import PlayAudios from "../../PlayAudios";
import {ICachedBooksInfo} from "types/ICachedBooksInfo";
import {localStorageKeys} from "config/localStorageKeys";
import {AudioActionsContext} from "context/audio";
import useScrollToTop from "hooks/useScrollToTop";

const Review: FC = () => {
    const { record, book, smartObject } = useContext(Context);
    const { setActiveStep } = useContext(ActionsContext);
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);

    useScrollToTop();

    const {setupSound} = useContext(AudioActionsContext);

    useEffect(() => {
        if (record) {
            setupSound(record);
        }
    }, [record, setupSound])

    const handleBackPress = useCallback(() => {
        setActiveStep(Steps.recording);
    }, [setActiveStep]);

    const handleClearRecordCache = useCallback(() => {
        if (smartObject) {
            const cachedBooksConfig: ICachedBooksInfo = JSON.parse(localStorage.getItem(localStorageKeys.booksConf) || '{}');
            delete cachedBooksConfig[smartObject.id];
            localStorage.setItem(localStorageKeys.booksConf, JSON.stringify(cachedBooksConfig));
            localStorage.removeItem(localStorageKeys.recAccessKey)
        }
    }, [smartObject]);

    const handleApprove = useCallback(() => {
        setActiveStep(Steps.save);
        handleClearRecordCache();
    }, [setActiveStep, handleClearRecordCache]);

    if (!record) return null;

    return (
        <section className={styles.wrap}>
            <button className={styles.back} onClick={handleBackPress}><ArrowLeft /></button>
            {
                book && <PlayAudios records={[record]} book={book} />
            }
            <div className={styles.approveBox}>
                <Button onClick={handleApprove}>{commonTxts.approve}</Button>
            </div>
        </section>
    )
}

export default Review;
