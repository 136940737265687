import React, {FC, useContext, useEffect, useMemo, useState} from "react";
import {ActionsContext, Context} from "../../context";
import stylesMain from "../../styles.module.scss";
import ArrowLeft from "components/icons/ArrowLeft";

import {IRecord} from "types/IRecord";
import PageError from "components/PageError";
import {textsCommon} from "texts/common";
import {LangActionsContext} from "context/lang";
import PlayAudios from "components/PlayAudios";


const PlayBackView: FC = () => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const { narrators, bookNarrators, selectedNarratorId, book } = useContext(Context);
    const { handleBackHome} = useContext(ActionsContext);

    const [narrator, setNarrator] = useState<IRecord | null>(null);

    useEffect(() => {
        const nar = [...(narrators || []), ...(bookNarrators || [])].find(({ id }) => id === selectedNarratorId);
        if (nar) {
            setNarrator(nar);
        } else {
            handleBackHome();
        }
    }, [handleBackHome, narrators, bookNarrators, selectedNarratorId]);

    const list: IRecord[] | null = useMemo(() => {
        if (selectedNarratorId) {
            const arr = [...(narrators || []), ...(bookNarrators || [])];
            const firstNarrator = arr.find(({id}) => id === selectedNarratorId);
            const res = arr.filter(({id}) => id === selectedNarratorId);
            return firstNarrator ? [firstNarrator, ...res] : res;
        }
        return null;
    }, [narrators, bookNarrators, selectedNarratorId]);

    const hasAudio = narrator?.full_audio?.src || narrator?.full_transition_audio?.src;

    if (!narrator) return null;
    return (
        <>
            <button className={stylesMain.backBtn} onClick={handleBackHome}><ArrowLeft/></button>
            {
                hasAudio && book ? (
                    <PlayAudios book={book} records={list || []} showMiniPlay />
                ) : (
                    <PageError text={commonTxts.narrationNotFound} />
                )
            }
        </>

    )
}

export default PlayBackView;
