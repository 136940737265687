import React, {FC} from "react";

const Info: FC = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 0.199997C5.93121 0.199997 0.200012 5.9312 0.200012 13C0.200012 20.0688 5.93121 25.8 13 25.8C20.0688 25.8 25.8 20.0688 25.8 13C25.8 5.9312 20.0688 0.199997 13 0.199997ZM14.0667 19.4H11.9333V11.9333H14.0667V19.4ZM13 9.26666C12.1168 9.26666 11.4 8.54986 11.4 7.66666C11.4 6.78346 12.1168 6.06666 13 6.06666C13.8832 6.06666 14.6 6.78346 14.6 7.66666C14.6 8.54986 13.8832 9.26666 13 9.26666Z"
            fill="black"/>
    </svg>
)

export default Info;
