import React, {ChangeEvent, FC, memo, useCallback, useContext, useEffect, useState} from "react";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {getAuth} from "firebase/auth";

import Title from "components/Title";

import styles from './styles.module.scss';
import {LangActionsContext} from "context/lang";
import {textsRecording} from "texts/recording";
import UserPhoto from "components/UserPhoto";
import LangSwitch from "components/BookLangSwitch";
import Button from "components/Button";
import {textsCommon} from "texts/common";
import {toBase64} from "utils/fileToBase64";
import {ActionsContext, Context} from "../context";
import {createEmptyRecordDoc, updateRecord, updateSmartObject} from "utils/firebase";
import {cacheBookInfo} from "utils/cacheBooksInfo";
import {Steps} from "../config";
import {storage} from "../../../firebase";
import {IRecord} from "types/IRecord";
import {ISmartObject} from "types/ISmartObject";
import {AppActionsContext} from "context/app";
import {resizeFile} from "../../../utils/cropImg";
import Tooltip from "../../Tooltip";
import useScrollToTop from "hooks/useScrollToTop";

const BookIntro: FC = () => {
    const { recordLang, book, record, smartObject } = useContext(Context);
    const { changeRecordLang, setActiveStep, fetchRecord } = useContext(ActionsContext);
    const { fetchSmartObject } = useContext(AppActionsContext);
    const {getLangTexts} = useContext(LangActionsContext);
    const remoteRecordTxts = getLangTexts(textsRecording);
    const commonTxts = getLangTexts(textsCommon);

    useScrollToTop();

    const [narratorPhoto, setNarratorPhoto] = useState<string | undefined>();
    const [file, setFile] = useState<File>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const showAddPhoto = localStorage.getItem(localStorageKeys.showAddPhotoOnRec) ? localStorage.getItem(localStorageKeys.showAddPhotoOnRec) === 'true' : true;
    const [showTooltip, setShowTooltip] = useState<boolean>(true);

    useEffect(() => {
        if (record) setNarratorPhoto(record?.narratorAvatar?.src || undefined);
    }, [record])

    const handleChangePhoto = useCallback(async (data: ChangeEvent<HTMLInputElement>) => {
        const file = data.target.files?.[0];
        if (file) {
            try {
                const b64File = await toBase64(file);
                setNarratorPhoto(b64File);
                setFile(file);
            } catch (err) {
                window.console.error(err)
            }
        }
    }, []);

    const savePhoto = useCallback(async (file: File) => {
        setIsLoading(true);
        const metadata = {
            contentType: 'image/jpeg',
        };

        try {
            const recId = record?.id || await createEmptyRecordDoc(); // if no cached record id - create new empty record
            cacheBookInfo({recordId: recId}); // caching here in case if get have errors in next action, to avoid creation many empty records
            const resizedFile = await resizeFile(file);
            const storageRef = ref(storage, `_recordings/${recId}/narratorAvatar`);
            const snap = await uploadBytes(storageRef, resizedFile, metadata);
            const imgUrl = await getDownloadURL(snap.ref);

            const canAddRecId = !smartObject?.recordingsList?.includes(recId);
            // save new record id to smart object
            if (smartObject && canAddRecId) {
                await updateSmartObject(smartObject.id, { recordingsList: [...(smartObject?.recordingsList || []), recId] } as ISmartObject);
                await fetchSmartObject(smartObject.id);
            }
            const auth = getAuth();
            await updateRecord(recId, {
                narratorAvatar: {src: imgUrl},
                smartObjectBook: smartObject?.id,
                lang: recordLang,
                narratorUserId: auth.currentUser?.uid || null,
            } as IRecord);
            setActiveStep(Steps.recording);
            await fetchRecord(recId);
        } catch (err) {
            window.console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, [fetchRecord, setActiveStep, record, recordLang, smartObject, fetchSmartObject]);

    const saveLang = useCallback(async () => {
        // if only lang is changed
        if (record && recordLang !== record?.lang?.toLowerCase() && !file) {
            setIsLoading(true);
            await updateRecord(record.id, {lang: recordLang} as IRecord);
            setActiveStep(Steps.recording);
            setIsLoading(false);
        }
    }, [record, recordLang, file, setActiveStep])


    const handleConfirmPress = useCallback(async () => {
        await saveLang();
        if (file) {
            savePhoto(file);
            return
        }
        setActiveStep(Steps.recording);
    }, [file, savePhoto, setActiveStep, saveLang]);

    const hideTooltip = useCallback(() => {
        setShowTooltip(false);
        // localStorage.setItem(localStorageKeys.showAddPhotoOnRec, 'false');
    }, [])

    return (
        <section className={styles.wrap} onClick={hideTooltip}>
            <Title className={styles.title}>{remoteRecordTxts.letChildReadStoryVoice}</Title>
            {
                book && (
                    <div className={styles.cover}>
                        <img
                            src={book.coverImg.src}
                            alt={book.name}
                        />
                        {smartObject?.ownerAvatar.src && (
                            <UserPhoto
                                className={styles.userPhoto}
                                photo={smartObject.ownerAvatar.src}
                            />
                        )}
                    </div>
                )
            }
            <div className={styles.addPhotoBox}>
                <Tooltip open={showTooltip} message={commonTxts.addYourPhotoHelpText} position='top' onClose={hideTooltip}>
                    <UserPhoto photo={narratorPhoto} handleChangePhoto={handleChangePhoto}/>
                </Tooltip>
            </div>
            <p className={styles.txt}>{remoteRecordTxts.narratorLang}</p>
            {recordLang && <LangSwitch selectedLang={recordLang} onLangChange={changeRecordLang} langs={book?.lang || []}/>}
            <div className={styles.actionBtn}>
                <Button
                    fullwidth
                    onClick={isLoading ? undefined : handleConfirmPress}
                    disabled={!narratorPhoto}
                    loading={isLoading}
                >
                    {isLoading ? commonTxts.saving : commonTxts.confirm}
                </Button>
            </div>
        </section>
    )
}

export default memo(BookIntro);
