import React, {FC} from "react";
import cn from "classnames";
import styles from './styles.module.scss';
import {Lang} from "config/lang";
import {flags} from "config/flags";

interface IProps {
    className?: string;
    langs: Lang[];
    selectedLang: Lang;
    onLangChange: (val: Lang) => void;
}

const BookLangSwitch:FC<IProps> = ({ className, langs, selectedLang, onLangChange }) => {
    const handleLangChange = (val: Lang) => () => {
        onLangChange(val);
    }

    return (
        <section className={cn(styles.langs, {[className || '']: className})}>
            {
                langs.map(lang => (
                    <button
                        className={cn({[styles.active]: selectedLang === lang})}
                        key={lang}
                        type='button'
                        title={lang}
                        onClick={handleLangChange(lang)}
                    >
                        <img src={flags[lang]} alt={lang}/>
                    </button>
                ))
            }
        </section>
    )
}

export default BookLangSwitch;
