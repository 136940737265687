import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {getRecord, getSmartBook} from "utils/firebase";
import {IBook} from "types/IBook";
import {AppActionsContext, AppContext} from "context/app";
import {IRecord} from "types/IRecord";
import {ISmartObject} from "types/ISmartObject";
import {View} from "./config";
import {AudioActionsContext} from "context/audio";

interface IContextData {
    book: IBook | null;
    isLoading: boolean;
    hasAccess: boolean;
    narrators: IRecord[] | null;
    bookNarrators: IRecord[] | null;
    smartObject: ISmartObject | undefined;
    view: View;
    selectedNarratorId: string | null;
}

interface IContextActions {
    handleSelectNarrator: (narId: string) => void;
    handleBackHome: () => void;
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = {
    book: null,
    isLoading: false,
    hasAccess: false,
    narrators: null,
    bookNarrators: null,
    smartObject: undefined,
    view: View.narrators,
    selectedNarratorId: null,
};
const initActions: IContextActions = {
    handleSelectNarrator: () => null,
    handleBackHome: () => null,
};

export const Context = createContext(initData);
export const ActionsContext = createContext(initActions);

const ContextProvider: FC<IContextProviderProps> = ({children}) => {
    const [book, setBook] = useState<IBook | null>(null);
    const [narrators, setNarrators] = useState<IRecord[] | null>(null);
    const [bookNarrators, setBookNarrators] = useState<IRecord[] | null>(null);
    const [view, setView] = useState<View>(View.narrators);
    const [selectedNarratorId, setSelectedNarratorId] = useState<string | null>(null);

    const { smartObject, isLoading, hasAccess } = useContext(AppContext);
    const { setIsLoading } = useContext(AppActionsContext);
    const { setupSound } = useContext(AudioActionsContext);

    const getInitData = useCallback(async () => {
        if (smartObject) {
            try {
                const bookData: IBook | null = await getSmartBook(smartObject.smartObjectBook);
                setBook(bookData);

                const narratorPromises: any[] = [];
                const bookNarratorPromises: any[] = [];

                smartObject?.recordingsList?.forEach((recordId) => {
                    narratorPromises.push(getRecord(recordId));
                });

                bookData?.recordingsList?.forEach((recordId) => {
                    bookNarratorPromises.push(getRecord(recordId));
                });

                const narratorsData = await Promise.all(narratorPromises);
                setNarrators(narratorsData || null);

                const bookNarratorsData = await Promise.all(bookNarratorPromises);
                setBookNarrators(bookNarratorsData || null);
            } catch (err) {

            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }, [smartObject, setIsLoading]);

    // fetch initial data;
    useEffect(() => {
        if (hasAccess) {
            getInitData();
        }
    }, [hasAccess, getInitData]);

    const handleSelectNarrator = useCallback((narId: string) => {
        const nar = [...(narrators || []), ...(bookNarrators || [])].find(({ id }) => id === narId);
        if (nar) {
            setupSound(nar);
        }
        setView(View.playback);
        setSelectedNarratorId(narId);
    }, [narrators, bookNarrators, setupSound])

    const handleBackHome = useCallback(() => {
        setView(View.narrators);
        setSelectedNarratorId(null);
    }, [])

    return (
        <Context.Provider
            value={useMemo(
                () => ({
                    isLoading,
                    hasAccess,
                    book,
                    narrators,
                    bookNarrators,
                    smartObject,
                    view,
                    selectedNarratorId,
                }),
                [isLoading, hasAccess, book, narrators, bookNarrators, smartObject, view, selectedNarratorId],
            )}
        >
            <ActionsContext.Provider value={useMemo(() => ({
                handleSelectNarrator,
                handleBackHome,
            }), [handleSelectNarrator, handleBackHome])}>
                {children}
            </ActionsContext.Provider>
        </Context.Provider>
    );
};

export default ContextProvider;
