import React, {FC} from 'react';
import {Route, Routes} from "react-router-dom";
import RemoteRecord from "pages/RemoteRecord";
import routes from "config/routes";
import Home from "pages/Home";
import Record from "pages/Record";
import App from "components/App";
// import Testing from "./pages/Testing";

const RoutesRoot: FC = () => {
  return (
      <App>
        <Routes>
            <Route path={routes.home} element={<Home />} />
            <Route path={routes.remoteRecord} element={<RemoteRecord />} />
            <Route path={routes.record} element={<Record />} />
            {/*<Route path={'/testing/'} element={<Testing />} />*/}
        </Routes>
      </App>
  );
}

export default RoutesRoot;
