import React, {FC} from "react";

const Delete: FC = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 8H6.66667H28" stroke="#FF563C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.6667 8.00008V5.33341C10.6667 4.62617 10.9477 3.94789 11.4478 3.4478C11.9479 2.9477 12.6262 2.66675 13.3334 2.66675H18.6667C19.374 2.66675 20.0523 2.9477 20.5524 3.4478C21.0525 3.94789 21.3334 4.62617 21.3334 5.33341V8.00008M25.3334 8.00008V26.6667C25.3334 27.374 25.0525 28.0523 24.5524 28.5524C24.0523 29.0525 23.374 29.3334 22.6667 29.3334H9.33341C8.62617 29.3334 7.94789 29.0525 7.4478 28.5524C6.9477 28.0523 6.66675 27.374 6.66675 26.6667V8.00008H25.3334Z" stroke="#FF563C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.3333 14.6667V22.6667" stroke="#FF563C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.6667 14.6667V22.6667" stroke="#FF563C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)

export default Delete;
