type Operation = "*" | "+" | "-";

export interface IMathQuestion {
    a: number;
    b: number;
    operation: Operation;
    answer: number;
}

const calculation = (operator: string, leftValue: number, rightValue: number) => {
    switch (operator) {
        case '+': return leftValue + rightValue;
        case '-': return leftValue - rightValue;
        // case '*': return leftValue * rightValue;
    }
}

export const ask = (): IMathQuestion => {
    let a = Math.floor(Math.random() * 10) + 1;
    let b = Math.floor(Math.random() * 10) + 1;

    // const operation = ["*", "+", "/", "-"][Math.floor(Math.random() * 4)];
    const operation: Operation = ["+", "-"][Math.floor(Math.random() * 2)] as Operation;

    if (operation === '-' && a < b) {
        const temp = a;
        a = b;
        b = temp;
    }

    return {
        a,
        b,
        operation,
        answer: calculation(operation, a, b) as number,
    }
}
