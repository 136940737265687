import React, {FC, MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import styles from './styles.module.scss';
import Loading from "../icons/Loading";
import cn from "classnames";

interface IProps {
    dataRef: MutableRefObject<any>
    onDone?: () => void;
}

const StartRecordCountDown: FC<IProps> = ({ dataRef, onDone }) => {
    const [count, setCount] = useState<number | undefined>(3);
    const [show, setShow] = useState<boolean>(false);
    const intervalRef = useRef<any>();

    const startCountDown = useCallback(() => {
        clearInterval(intervalRef.current);
        let tic = 2;
        intervalRef.current = setInterval(() => {
            if (tic === 0) {
                clearInterval(intervalRef.current);
                setCount(undefined);
                if (onDone) onDone();
                return;
            }
            setCount(tic);
            tic = tic - 1;
        }, 800);
    }, [onDone]);

    useEffect(() => {
        if(dataRef) {
            dataRef.current = {
                start: () => {
                    setShow(true);
                    setCount(3);
                    startCountDown();
                },
                stop: () => {
                    setShow(false);
                }
            }
        }
    }, [startCountDown, dataRef])

    return (
        <div className={cn(styles.countDown, {[styles.visible]: show})}>
            {count ? count : (
                <Loading />
            )}
        </div>
    )
}

export default StartRecordCountDown;
