import React, {FC} from "react";

const ArrowLeft: FC = () => (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.9168 17H7.0835" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.0002 26.9168L7.0835 17.0002L17.0002 7.0835" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowLeft;
