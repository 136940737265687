import React, {FC, ReactNode} from "react";
import styles from './styles.module.scss';

interface IProps {
    children: ReactNode | ReactNode[];
}

const Container: FC<IProps> = ({ children }) => (
    <div className={styles.container}>
        {children}
    </div>
)

export default Container;
