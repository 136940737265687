import React, {FC} from "react";

const ForwardCircleArrow: FC = () => (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.8689 3.09165C11.9369 2.81761 9.96813 3.17892 8.25931 4.12115C6.55049 5.06337 5.1942 6.53546 4.39482 8.31558C3.59543 10.0957 3.39626 12.0874 3.82732 13.9906C4.25838 15.8937 5.29631 17.6052 6.78471 18.8672C8.27311 20.1291 10.1313 20.8731 12.0794 20.9871C14.0274 21.1011 15.9597 20.5788 17.5851 19.499C19.2105 18.4192 20.4409 16.8404 21.091 15.0005C21.3669 14.2194 22.2239 13.8099 23.005 14.0859C23.7861 14.3618 24.1956 15.2187 23.9196 15.9999C23.0529 18.4531 21.4123 20.5582 19.2452 21.9979C17.078 23.4376 14.5016 24.1339 11.9042 23.982C9.30679 23.83 6.82915 22.838 4.84462 21.1554C2.86009 19.4728 1.47618 17.1908 0.901432 14.6533C0.326688 12.1157 0.592248 9.46011 1.6581 7.08661C2.72394 4.71312 4.53233 2.75034 6.81075 1.49404C9.08917 0.237742 11.7142 -0.24401 14.2902 0.12138C16.8585 0.485669 19.2394 1.67232 21.0763 3.50323L23.9336 6.19373V2.66651C23.9336 1.83808 24.6052 1.16651 25.4336 1.16651C26.262 1.16651 26.9336 1.83808 26.9336 2.66651V9.66651C26.9336 10.4949 26.262 11.1665 25.4336 11.1665H18.4336C17.6052 11.1665 16.9336 10.4949 16.9336 9.66651C16.9336 8.83808 17.6052 8.16651 18.4336 8.16651H21.6525L19.0036 5.67224C18.9926 5.66183 18.9817 5.65125 18.971 5.64051C17.5916 4.26024 15.8009 3.36569 13.8689 3.09165Z"
              fill="white"/>
    </svg>

)

export default ForwardCircleArrow;
