import React, {FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import UserPhoto from "components/UserPhoto";
import styles from './styles.module.scss';
import {flags} from "config/flags";
import {Lang} from "config/lang";
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import Play from "components/icons/Play";
import Pause from "components/icons/Pause";
import BackCircleArrow from "components/icons/BackCircleArrow";
import ForwardCircleArrow from "components/icons/ForwardCircleArrow";
import {ICustomMap} from "types/ICustomMap";
import AudioTime from "./AudioTime";
import Slider from "./Slider";
import {IRecord} from "types/IRecord";
import {IBook} from "types/IBook";
import PageError from "../PageError";
import cn from "classnames";
import {AudioActionsContext, AudioContext} from "context/audio";
import CrossedBell from "../icons/CrossedBell";
import Bell from "../icons/Bell";

interface IProps {
    records: IRecord[];
    book: IBook;
    showMiniPlay?: boolean;
}

const PlayAudios: FC<IProps> = ({records, book, showMiniPlay = false}) => {
    const [activePage, setActivePage] = useState(1);

    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);

    const { sound, withBell, soundRangesRef } = useContext(AudioContext);
    const { toggleSwitchBell } = useContext(AudioActionsContext);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [record, setRecord] = useState<IRecord | null>(null);
    const [showSinglePlay, setShowSinglePlay] = useState<boolean>(showMiniPlay)

    const audioIntervalRef = useRef<any>();

    useEffect(() => {
        if (records) {
            setRecord(records[0]);
        }
    }, [records])

    const onPlaying = useCallback(() => {
        if (sound) {
            const seek = (sound.seek() || 0) * 1000;
            document.dispatchEvent(new CustomEvent("audio-time-update", {
                detail: {
                    seek: seek,
                },
            }));
            setActivePage(actPage => {
                const milisec = Math.floor(seek);
                const ranges = soundRangesRef.current;
                let pageNumber = actPage;
                Object.keys(ranges).forEach((key: string) => {
                    // console.log('key', key);
                    const arr = key.split('-');
                    const start = +arr[0];
                    const end = +arr[1];
                    if (milisec >= start && milisec <= end) {
                        // console.log('new page', ranges[key])
                        pageNumber = ranges[key];
                    }
                });
                return pageNumber;
            })
        }
    }, [sound, soundRangesRef]);

    useEffect(() => {
        if (record && sound) {
            sound.on('play', () => {
                audioIntervalRef.current = setInterval(onPlaying, 900);
                setIsPlaying(true);
            })
            sound.on('seek', onPlaying);
            sound.on('pause', () => {
                clearInterval(audioIntervalRef.current);
                setIsPlaying(false);
            })
            sound.on('end', () => {
                clearInterval(audioIntervalRef.current);
                setIsPlaying(false);
            })
        }
    }, [record, onPlaying, sound]);

    useEffect(() => {
        return () => {
            clearInterval(audioIntervalRef.current);
            sound?.unload();
        }
    }, [sound])

    const handlePlayPress = useCallback(() => {
        if (sound) {
            sound.play();
        }
    }, [sound]);

    const handlePausePress = useCallback(() => {
        if (sound) {
            sound.pause();
        }
    }, [sound]);

    const handleSecBackPress = useCallback(() => {
        if (sound) {
            sound.seek(sound.seek() - 15);
        }
    }, [sound]);

    const handleSecNextPress = useCallback(() => {
        if (sound) {
            sound.seek(sound.seek() + 15);
        }
    }, [sound]);

    const carouselList = useMemo(() => {
        return book?.recPages?.map(({pageNum, img}) => ({
            img: img.src,
            pageNum,
        }));
    }, [book]);

    const onSliderChange = useCallback((data: ICustomMap) => {
        if (data && sound) {
            setActivePage(data.pageNum);
            const ranges = soundRangesRef.current;
            const rangKey = Object.keys(ranges).find((key) => ranges[key] === data.pageNum)
            if (rangKey) {
                const start = +rangKey.split('-')[0];
                sound.seek(start / 1000);
            }
        }
    }, [sound, soundRangesRef]);

    const handleNarratorPress = useCallback(() => {
        if (isPlaying) {
            setShowSinglePlay(false);
        } else {
            sound?.pause();
            sound?.play();
        }
    }, [isPlaying, sound])

    const recordLang = record?.lang;

    let fullDuration: number = useMemo(() => record?.pages.reduce((acc: number, {duration}) => {
        acc += duration; // milliseconds
        return acc;
    }, 0) || 0, [record]);

    if (!record) return null;
    if (!record.full_audio?.src && !record.full_transition_audio?.src) return <PageError
        text={commonTxts.audioIsNotGenerated}/>

    return (
        <>
            <div className={styles.pingSwitcher}>
                <button type='button' onClick={toggleSwitchBell}>
                    <span className={cn({[styles.active]: !withBell})}><CrossedBell /></span>
                    <span className={cn({[styles.active]: withBell})}><Bell /></span>
                </button>
            </div>
            <div className={cn(styles.mini, {[styles.showMini]: showSinglePlay})}>
                <div className={cn(styles.pulseBox, {[styles.playing]: showMiniPlay && isPlaying})}>
                    <button type="button" onClick={handleNarratorPress}>
                        {!isPlaying && <Play />}
                        <UserPhoto className={styles.photo} photo={record.narratorAvatar.src}/>
                    </button>
                </div>
            </div>

            <div className={styles.photoBox}>
                <div>
                    <UserPhoto photo={record?.narratorAvatar.src} flag={flags[recordLang as Lang]}/>
                </div>
            </div>
            <div className={styles.sliderBox}>
                <Slider showSlide={activePage - 2} list={carouselList} onChange={onSliderChange}/>
            </div>
            <div className={styles.audioTimeBox}>
                {
                    <AudioTime totalTime={fullDuration}/>
                }
            </div>
            <div className={styles.audioControls}>
                <button className={styles.secBtn} onClick={handleSecBackPress}>
                    <span>15 {commonTxts.sec}</span>
                    <BackCircleArrow/>
                </button>
                <button className={styles.pausePlay} onClick={isPlaying ? handlePausePress : handlePlayPress}>
                    {isPlaying ? <Pause/> : <Play/>}
                </button>
                <button className={styles.secBtn} onClick={handleSecNextPress}>
                    <span>15 {commonTxts.sec}</span>
                    <ForwardCircleArrow/>
                </button>
            </div>
        </>
    )
}

export default memo(PlayAudios);
