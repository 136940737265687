import React, {ChangeEvent, FC, useCallback, useContext, useEffect, useState} from "react";
import styles from './styles.module.scss';
import {LangActionsContext} from "context/lang";
import {textsCommon} from "texts/common";
import Button from "../Button";
import ErrorCircled from "../icons/ErrorCircled";
import {ask, IMathQuestion} from "utils/mathQuestion";
import {ICustomMap} from "../../types/ICustomMap";

interface IProps {
    generate?: boolean;
    onFinnish: () => void,
}

const MathQuestion: FC<IProps> = ({generate, onFinnish}) => {
    const {getLangTexts} = useContext(LangActionsContext);
    const commonTxts = getLangTexts(textsCommon);
    const [val, setVal] = useState<number | string>();
    const [question, setQuestion] = useState<IMathQuestion>();
    const [showErr, serShowErr] = useState<boolean>(false);

    useEffect(() => {
        setQuestion(ask());
    }, [generate])

    const checkRes = useCallback((input: string | number) => {
        if (input.toString() === question?.answer.toString()) {
            onFinnish();
        } else {
            serShowErr(true);
        }
    }, [question, onFinnish])

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setVal(evt.target.value);
        serShowErr(false);
    }

    const handleAccept = useCallback(() => {
        checkRes(val || '');
    }, [checkRes, val])

    const onKeyDown = useCallback((evt: ICustomMap) => {
        if (evt?.code === "Enter" || evt?.key === 'Enter' || evt?.which === 13) {
            checkRes(evt.target.value);
        }
    }, [checkRes])

    return (
        <div className={styles.wrap}>
            {
                question && (
                    <div className={styles.questionBox}>
                        <p>
                            <span>{question.a}</span>
                            <span>{question.operation}</span>
                            <span>{question.b}</span>
                            <span>=</span>
                            <span>?</span>
                        </p>
                    </div>
                )
            }
            <input
                value={val}
                type='number'
                pattern="[0-9]*"
                inputMode="decimal"
                placeholder={commonTxts.yourAnswer}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            {
                showErr && (
                    <div className={styles.err}><ErrorCircled />{val ? commonTxts.answNotCorrect : commonTxts.enterValue}</div>
                )
            }
            <div className={styles.btnBox}>
                <Button className={styles.accept} onClick={handleAccept} disabled={!val}>{commonTxts.accept}</Button>
            </div>
        </div>
    )
}

export default MathQuestion;
