import React, {FC} from "react";

const BackCircleArrow: FC = () => (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.7334 1.8335C2.56183 1.8335 3.2334 2.50507 3.2334 3.3335V8.6335H8.5334C9.36183 8.6335 10.0334 9.30507 10.0334 10.1335C10.0334 10.9619 9.36183 11.6335 8.5334 11.6335H1.7334C0.904971 11.6335 0.233398 10.9619 0.233398 10.1335V3.3335C0.233398 2.50507 0.904971 1.8335 1.7334 1.8335Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5684 0.824573C15.0803 0.471164 17.639 0.943676 19.859 2.17092C22.079 3.39815 23.84 5.31364 24.8767 7.62875C25.9134 9.94386 26.1697 12.5332 25.6068 15.0066C25.0439 17.48 23.6925 19.7035 21.7561 21.342C19.8197 22.9805 17.4032 23.9454 14.8707 24.0911C12.3383 24.2369 9.82708 23.5557 7.71546 22.1501C5.60384 20.7446 4.00622 18.6908 3.16331 16.2983C2.88802 15.517 3.29827 14.6604 4.07963 14.3851C4.86098 14.1098 5.71755 14.5201 5.99283 15.3014C6.61961 17.0805 7.80759 18.6076 9.37777 19.6528C10.9479 20.6979 12.8153 21.2045 14.6984 21.0961C16.5815 20.9877 18.3783 20.2703 19.8182 19.0519C21.2581 17.8335 22.263 16.1801 22.6816 14.3409C23.1001 12.5017 22.9096 10.5763 22.1387 8.85483C21.3678 7.13334 20.0583 5.709 18.4076 4.79644C16.7568 3.88388 14.8542 3.53252 12.9864 3.79531C11.1185 4.05811 9.38671 4.92081 8.05183 6.25344C8.04113 6.26412 8.03026 6.27465 8.01923 6.28501L2.76057 11.2263C2.15685 11.7936 1.20756 11.7641 0.640273 11.1604C0.0729859 10.5567 0.102519 9.60737 0.706238 9.04008L5.94893 4.11376C7.74186 2.33102 10.0642 1.17689 12.5684 0.824573Z" fill="white"/>
    </svg>
)

export default BackCircleArrow;
